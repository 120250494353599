import { Avatar, ButtonGroup } from '@material-ui/core';
import { Button, Collapse, FormLabel, Grid, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { Multiselect, TextBox, DatePicker } from '../../../../../Core/FormInput';

import { useState } from 'react';
// import { ProfitTable } from './profitlossTable';
import { useEffect } from 'react';
import moment from 'moment';
import ShowChartIcon from '@material-ui/icons/ShowChart';

import { FilterListIcon } from '@material-ui/icons/FilterList';
import { BudgetSendBtn, FilterBtn, LinkedButton, SmallLinkedButton } from '../BudgetCore/budgetButton';
import { AgedStockCard, AgedStocksCardTWO, AgedStocksDayRangeCard, ProfitLossCards, UsedStocksCard, UsedStocksCardTWO } from '../BudgetCore/budgetCardsUi_v2';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import CallMadeIcon from '@material-ui/icons/CallMade';
import EventIcon from '@material-ui/icons/Event';
import { PandLLineChart } from '../BudgetCore/p&ldetailsChart';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import UsedStocksTable from './usedStockTable';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { getAgedStockValuation } from '../../../../../Core/Service/ReportScreens-service';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { useHistory } from 'react-router-dom';
import CircularIndeterminate from '../BudgetCore/loader';
import { useWindowSize } from '../../../../../Core/Controls/ScreenResolution';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';

const crumbs = () => [
    { name: 'Home', path: '/' },
    { name: 'Used Stock', active: true },
    { name: 'Aged Vehicle Stock', active: true }
];

const AgedStocks = () => {
    const [WindowWidths, WindowHeights] = useWindowSize();

    const history = useHistory();
    const portalNicolJson = JSON.parse(localStorage.getItem('NicoleFilterData'));
    const [state, setState] = useState({ avgVehicle: [], mainData: [], vehilceSplitByDays: [], showLoader: true });

    useEffect(async () => {
        let res = await getAgedStockValuation();
        if (res.success) {
            let groupStock = res.data.gpStock[0];
            setState((st) => ({
                ...st,
                avgSIV: groupStock.avgSIV,
                totalCAPValue: groupStock.totalCAPValue,
                totalStockValue: groupStock.totalStockValue,
                totalUnitStock: groupStock.totalUnitStock,
                avgVehicle: res.data.avgVehicle,
                totalVariance: groupStock.totalCAPValue - groupStock.totalStockValue,
                mainData: res.data.mainData,
                vehilceSplitByDays: res.data.vehilceSplitByDays,
                showLoader: false
            }));
        }
    }, []);

    const handleRedirect = (link) => {
        localStorage.setItem('NicoleFilterData', JSON.stringify({ ...portalNicolJson, groupIds: [] }));
        history.push({
            pathname: link
        });
    };

    let barColor = {
        1: '#569101',
        2: '#FFBF00',
        3: 'red'
    };

    return (
        <div className="salesBudgetScreen">
            {state.showLoader ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <Grid container alignContent="center" justifyContent="space-between">
                        <Grid item>
                            <BreadCrumbs crumbs={crumbs()} />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="space-evenly">
                        <Grid item xs={12} sm={12} lg={5} xl={5} className="containerPadding">
                            <Grid container alignItems="flex-start" justifyContent="space-between" className="USVGropupStockViewKip_card">
                                <Grid item xs={10} xl={10}>
                                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                                        <Grid item xs={12}>
                                            <div className="AVSsecondaryHeading">GROUP STOCK</div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                <EventIcon className="AvatarFont" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ width: '100%' }}>
                                                                <div className="AVStype1_Text alignLeft">{state.totalUnitStock || 0}</div>
                                                                <div className="AVStype3_Text alignLeft">Total Units in Stock</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                <EventIcon className="AvatarFont" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ width: '100%', marginBottom: 10 }}>
                                                                <div className="AVStype1_Text alignLeft">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state.totalStockValue)}
                                                                </div>
                                                                <div className="AVStype3_Text alignLeft">Total Stock Value</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                <EventIcon className="AvatarFont" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div style={{ width: '100%', marginBottom: 10 }}>
                                                                <div className="AVStype1_Text alignLeft">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state.totalCAPValue)}
                                                                </div>
                                                                <div className="AVStype3_Text alignLeft">Total CAP Clean</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <div aria-label="recipe" className="AvtarIcon" style={{ backgroundColor: '#F90677' }}>
                                                                <EventIcon className="AvatarFont" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item>
                                                            <div className="AVStype1_Text alignLeft">
                                                                <span>{formatters.CurrencyThousandSeparatorWithoutZero(Math.abs(state.totalVariance))}</span>
                                                                <span style={{ verticalAlign: 'middle' }}>
                                                                    {state.totalVariance < 0 ? (
                                                                        <ArrowDownwardIcon className="AVStype1_Text" style={{ color: 'red' }} />
                                                                    ) : (
                                                                        <ArrowUpwardIcon className="AVStype1_Text" style={{ color: '#569101' }} />
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className="AVStype3_Text alignLeft">Total Variance</div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} xl={2}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <SmallLinkedButton onClick={() => handleRedirect('/usedStock')}>
                                                        STOCK LIST &nbsp; <CallMadeIcon style={{ fontSize: 18 }} />
                                                    </SmallLinkedButton>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SmallLinkedButton onClick={() => handleRedirect('/overagePriority')}>
                                                        OVERAGE &nbsp; <CallMadeIcon style={{ fontSize: 18 }} />
                                                    </SmallLinkedButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={7} xl={7} className="containerPadding">
                            <div>
                                <div style={{ display: 'flex', height: '100%' }}>
                                    <div style={{ width: '100%' }}>
                                        <div className="f-RegularSize alignLeft mb10">AVERAGE VEHICLE AGE PER SITE</div>{' '}
                                        <ResponsiveContainer className="bo-Graph">
                                            <BarChart data={state.avgVehicle}>
                                                {/* <Bar dataKey="vehicleCount" fill="#8884d8" maxBarSize={35} /> */}
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Tooltip contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }} />
                                                <Bar dataKey="count" fill={'white'} barSize={30} height={40}>
                                                    {/* <LabelList dataKey="Total" content={renderCustomizedLabel} /> */}
                                                    {state.avgVehicle.map((entry, index) => {
                                                        let colorCode;
                                                        if (entry.count <= 50) {
                                                            colorCode = 1;
                                                        } else if (entry.count >= 50 && entry.count < 150) {
                                                            colorCode = 2;
                                                        } else if (entry.count >= 150) {
                                                            colorCode = 3;
                                                        }
                                                        return (
                                                            <Cell
                                                                key={`cell-${index}`}
                                                                fill={barColor[colorCode]}
                                                                // onClick={() => {
                                                                //     handleDetailpopUp(entry, 'VMRS');
                                                                // }}
                                                            />
                                                        );
                                                    })}
                                                </Bar>
                                                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                                <XAxis
                                                    dataKey="branchName"
                                                    textAnchor="end"
                                                    sclaeToFit="true"
                                                    // verticalAnchor="start"
                                                    // allowDataOverflow={false}
                                                    // interval={0}
                                                    // dy={-5}
                                                    // angle={'-20'}
                                                    style={{
                                                        fontSize: 12,
                                                        fontWeight: 700
                                                    }}
                                                />
                                                <YAxis orientation="left" style={{ fontSize: 8 }} hide />
                                                <Tooltip />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    {state.mainData.map((m) => {
                        return (
                            <>
                                <Grid container xl={12} justifyContent="space-evenly" alignItems="center">
                                    <Grid item xl={1} lg={1} md={4} sm={4} xs={12} className="containerPadding">
                                        <div
                                            className="AVSUnitKipOne_card"
                                            style={{ background: '#569101', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <div>
                                                <div className="mainHeading">{m.units}</div>
                                                <div className="mainHeading">Units</div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xl={4} lg={4} md={8} sm={8} xs={12} className="containerPadding">
                                        <AgedStockCard
                                            data={m}
                                            color="#569101"
                                            stockLink={'/usedStock'}
                                            priorityLink={'/overagePriority'}
                                            branchID={m.branchID}
                                            history={history}
                                        />
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={12} sm={12} xs={12} className="containerPadding">
                                        <AgedStocksDayRangeCard branchID={m.branchID} list={state.vehilceSplitByDays} />
                                    </Grid>
                                </Grid>
                            </>
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default AgedStocks;
