import { useEffect, useState } from 'react';
import { Button, ButtonGroup, Grid, makeStyles } from '@material-ui/core';
import { Card } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        border: '2px solid #fbfbfb',
        Opacity: 0.9,
        minWidth: 110,
        padding: '5px 0px', // margin: 3,
        borderRadius: 7,
        background: '#fbfbfb'
        // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px'
    }
}));
const MapQCCard = (props) => {
    const classes = useStyles();

    const ConvertOps = (ops) => {
        return ops?.map((op) => {
            return {
                type: `${op[props.valuePropertyName || 'type']}`,
                name: `${op[props.textPropertyName || 'name']}`
            };
        });
    };

    const [state, setState] = useState({
        ButtonList: ConvertOps(props.ButtonList) || []
    });

    useEffect(() => {
        props.ButtonList && setState((st) => ({ ...st, ButtonList: ConvertOps(props.ButtonList) }));
    }, [props.ButtonList]);

    return (
        <Card className={classes.root}>
            <Grid container justifyContent="space-between">
                <Grid item xs={12} sm={6} style={{ float: 'right' }}>
                    <div style={{ width: '100%', textAlign: 'left', color: '#f50057', fontWeight: 600, margin: '10px 4px' }}>
                        <ButtonGroup color="secondary" ria-label="outlined primary button group" size="small">
                            <b style={{ padding: '0px 18px' }}>{props.Title} &nbsp;</b>
                            {state.ButtonList.map((btn) => {
                                return (
                                    <Button
                                        variant={props.hourType === btn.type ? 'contained' : 'outlined'}
                                        onClick={() => props.handleBar(btn.type)}
                                        style={{ fontSize: 10 }}
                                    >
                                        {btn.name}
                                    </Button>
                                );
                            })}
                        </ButtonGroup>
                    </div>
                </Grid>
            </Grid>
            <div style={{ padding: '0px 18px' }}>
                <hr
                    style={{
                        flexShrink: 0,
                        borderWidth: '0px 0px thin',
                        borderStyle: 'solid',
                        borderColor: 'rgba(58, 53, 65, 0.12)'
                        // margin: '1.1rem 0px'
                    }}
                />
            </div>
            <Grid container spacing={1} style={{ padding: '0px 18px' }}>
                {props.List?.map((m) => {
                    let val = (m.qcDone / m.totalJobs) * 100;
                    let fix = val.toFixed(2);
                    return (
                        <Grid item style={{ padding: '10px 10px' }}>
                            <div
                                style={{
                                    borderLeft: '3px solid #00F433',
                                    padding: '5px 10px 0px 10px',
                                    width: '100%'
                                }}
                            >
                                <div
                                    style={{
                                        paddingBottom: 10,
                                        textAlign: 'left'
                                    }}
                                >
                                    <span style={{ fontSize: 13, fontWeight: 550, marginBottom: 10, fontFamily: `"Public Sans", sans-serif` }}>
                                        {m[props.render]}
                                    </span>
                                    <div>
                                        <span style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>{m.qcDone}</span>/{m.totalJobs}
                                        &nbsp; <b>({fix}%)</b>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
        </Card>
    );
};

export default MapQCCard;
