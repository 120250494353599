import { Button, CircularProgress, Grid, Box, ButtonGroup, Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { SecondaryButton, PrimaryButton, GreenButton } from '../../../Core/FormInput';
import React, { useEffect, useContext, useRef, useState } from 'react';
import AppContext from '../../../App/AppContext';
import { useHistory } from 'react-router-dom';
import { BreakdownLocation } from './ChildComp/BreakdownLocation';
import { CustDetails } from './ChildComp/CustomerDetails';
import { FieldWrappper } from '../../../Core/FieldWrapper';
import { HomeAccDealer } from './ChildComp/HomeAccDealer';
import { RentalFleetDetails } from './ChildComp/RentalFleetDetails';
import { VehicleDetails } from './ChildComp/VehicleDetails';
import { DefectSection } from './ChildComp/DefectSection';
import { RepairsCarriedOut } from './ChildComp/RepairsCarriedOut';
import { RecoveryDetails } from './ChildComp/RecoveryDetails';
import { JobChecks } from './ChildComp/JobChecks';
import { RepairType } from './ChildComp/RepairType';
import { DriverDetails } from './ChildComp/DriverDetails';
import { BasicFooter } from './ChildComp/BasicFooter';
import { getService24ByID, getService24Dropdowns, postService24 } from '../../../Core/Service/service24-service';
import PartDialog from './service24controlFrom';
import formatters from '../../../Core/Grid/inputFormatter';
import resizeImage from '../../../Core/CommonFunctions/base64ImgResizer';
import { TextBoxShrink } from '../../../Core/FormInput';
import { getDiagnosticSheetHeaderDetails } from '../../../Core/Service/diagnosticSheets';
import { BreakdownDefectRepair } from './ChildComp/BreakdownDefectRepair';
import ServiceTime from './ChildComp/serviceTime';
import WorkRequestedModal from './ChildComp/workRequested';
// import { FieldWrappper } from './../../../Core/FieldWrapper/index';

const Service24Modal = (props) => {
    const { hideModal, showToast, userRoles, hideTopNavAndMenu, displayName } = useContext(AppContext);
    const isAuditor = userRoles.includes('auditor');
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const HeaderId = props.match.params.jobId;
    const [service24PartsVM, setservice24PartsVM] = useState([
        {
            service24PartID: null,
            service24PartNumber: '',
            service24PartDescription: '',
            service24PartQuantity: 0,
            service24PartPrice: null
        }
    ]);

    const [service24TimesVM, setService24TimesVM] = useState([
        {
            service24TimeID: null,
            service24Name: '',
            service24DrivingStartTime: '',
            service24DrivingEndTime: '',
            service24RepairStartTime: '',
            service24RepairEndTime: '',
            service24ServiceVan: '',
            service24DrivingStartMileage: null,
            service24DrivingFinishMileage: null,
            service24FinishPostCode: '',
            service24StartPostCode: ''
        }
    ]);

    const [service24WorkDetailsVM, setService24WorkDetailsVM] = useState([
        {
            service24WorkID: null,
            service24WorkRequested: '',
            service24WorkReason: '',
            service24WorkAction: '',
            service24WorkTimeTaken: null
        }
    ]);

    const [control, setControl] = useState({ service24PaymentTypes: [], service24PaymentTypeID: '' });

    // const [showPartDialog, setShowPartDialog] = useState(false);

    useEffect(async () => {
        fromStep && hideTopNavAndMenu();
        let res = await getService24Dropdowns();
        if (res.success) {
            setControl((st) => ({ ...st, service24PaymentTypes: res.data.service24PaymentTypes }));
        }
        let headerRes = await getDiagnosticSheetHeaderDetails(HeaderId);
        let headerdata = headerRes?.data?.headerDetails[0];
        setVehDetails((st) => ({
            ...st,
            showCircularLoader: true,
            wipNumber: headerdata.wipNumber,
            registration: headerdata.registration,
            service24ChassisNo: headerdata.chassisNumber,
            service24EngineNo: headerdata.engineNumber,
            service24RegDate: formatters.DateFormatter(headerdata.dateDueIn),
            service24OdometerReading: headerdata.mileageIn,
            service24RegNumber: headerdata.registration,
            service24VehicleType: headerdata.vehicleType,
            service24JobID: headerdata.service24ID
        }));
        if (!headerdata?.service24ID) {
            setCustomerDetails((st) => ({
                ...st,
                service24CustomerName: headerdata.customerName || '',
                service24CustomerAddress1: headerdata.address1 || '',
                service24CustomerAddress2: headerdata.address2 || '',
                service24CustomerCity: headerdata.city || '',
                service24CustomerPostCode: headerdata.postcode || '',
                service24CustomerOrderNo: headerdata.customerOrderNo || ''
            }));
        }
    }, []);
    const isTechnician = userRoles.includes('technician');

    const [basicHeaderDetails, setBasicHeaderDetails] = useState({
        date: '1989-10-22',
        time: '23:59'
    });
    const [customerDetails, setCustomerDetails] = useState({ errors: {} });
    const [homeAccDealer, setHomeAccDealer] = useState({});
    const [rentalFleetDetails, setRentalFleetDetails] = useState({});
    const [vehDetails, setVehDetails] = useState({ showCircularLoader: false });

    // const [breakdownLocation, setBreakdownLocation] = useState({});
    // const [defect, setDefect] = useState({});
    // const [repairsCarriedOut, setRepairsCarriedOut] = useState({});
    const [breakdownDefectRepairDefails, setBreakdownDefectRepairDefails] = useState({});

    const [recoveryDetails, setRecoveryDetails] = useState({});
    const [jobChecks, setJobChecks] = useState({});
    const [repairType, setRepairType] = useState({
        service24TechnicianName: isTechnician ? displayName : '',
        service24TemporaryRepair: false,
        service24IncompleteRepair: false,
        service24TechnicianSignature: ''
    });
    const [otherDetails, setOtherDetails] = useState({
        cacAdvisedDate: '1989-10-22',
        cacAdvisedTime: '23:59'
    });
    const [driversDetails, setDriversDetails] = useState({});
    const handleYesNo = (name, val) => {
        setRecoveryDetails((st) => ({ ...st, [name]: val }));
    };
    //let service24JobID = 'E1438FCC-6BDB-4C5B-8D20-D6A458DF4E78';
    // const hidePartDialog = () => setShowPartDialog(false);

    const setField = (e) => {
        const { name, type, value } = e.target;
        setBasicHeaderDetails((st) => ({ ...st, [name]: value }));
    };

    const handleInput = (e) => {
        let { name, value } = e.target;
        setControl((st) => ({ ...st, [name]: value }));
    };

    useEffect(async () => {
        if (vehDetails.service24JobID) {
            let res = await getService24ByID(vehDetails.service24JobID);
            let data = res?.data?.service24Jobs[0];
            if (res.success) {
                setVehDetails((st) => ({
                    ...st,
                    showCircularLoader: true,
                    service24JobID: data.service24JobID,
                    service24VehicleType: data.service24VehicleType,
                    service24ChassisNo: data.service24ChassisNo,
                    service24EngineNo: data.service24EngineNo,
                    service24RegDate: formatters.DateFormatter(data.service24RegDate),
                    service24Colour: data.service24Colour,
                    service24GVW: data.service24GVW,
                    service24Load: data.service24Load,
                    service24RegNumber: data.service24RegNumber,
                    service24Destination: data.service24Destination,
                    service24TrailerOwner: data.service24TrailerOwner,
                    service24TrailerNo: data.service24TrailerNo,
                    service24OdometerReading: data.service24OdometerReading
                }));
                setBasicHeaderDetails((st) => ({ ...st, service24JobCaseNo: data.service24JobCaseNo }));
                setCustomerDetails((st) => ({
                    ...st,
                    service24CustomerName: data.service24CustomerName || '',
                    service24CustomerAddress1: data.service24CustomerAddress1 || '',
                    service24CustomerAddress2: data.service24CustomerAddress2 || '',
                    service24CustomerCity: data.service24CustomerCity || '',
                    service24CustomerPostCode: data.service24CustomerPostCode || '',
                    service24CustomerContactName: data.service24CustomerContactName || '',
                    service24CustomerPhone: data.service24CustomerPhone || '',
                    service24CustomerOrderNo: data.service24CustomerOrderNo || ''
                }));
                setHomeAccDealer((st) => ({
                    ...st,
                    service24HomeAccountDealerName: data.service24HomeAccountDealerName,
                    service24HomeAccountDealerContactName: data.service24HomeAccountDealerContactName,
                    service24HomeAccountDealerOrderNo: data.service24HomeAccountDealerOrderNo,
                    service24HomeAccountDealerLimit: data.service24HomeAccountDealerLimit || null
                }));
                setRentalFleetDetails((st) => ({
                    ...st,
                    service24RentalCompany: data.service24RentalCompany || '',
                    service24RentalPhone: data.service24RentalPhone || '',
                    service24RentalContactName: data.service24RentalContactName || '',
                    service24RentalOrderNo: data.service24RentalOrderNo || ''
                }));
                // setBreakdownLocation((st) => ({
                //     ...st,
                //     service24BreakdownLocation: data.service24BreakdownLocation || ''
                // }));
                // setDefect((st) => ({
                //     ...st,
                //     service24DefectDescription: data.service24DefectDescription || ''
                // }));
                // setRepairsCarriedOut((st) => ({
                //     ...st,
                //     service24RepairDetails: data.service24RepairDetails || ''
                // }));
                setBreakdownDefectRepairDefails((st) => ({
                    ...st,
                    service24BreakdownLocation: data.service24BreakdownLocation || '',
                    service24DefectDescription: data.service24DefectDescription || '',
                    service24RepairDetails: data.service24RepairDetails || ''
                }));
                setRecoveryDetails((st) => ({
                    ...st,
                    service24Recovered: data.service24Recovered || false,
                    service24RecoveryStartLocation: data.service24RecoveryStartLocation,
                    service24RecoveryEndLocation: data.service24RecoveryEndLocation,
                    service24RecoveryDistance: data.service24RecoveryDistance,
                    service24RecoveryTowingCompany: data.service24RecoveryTowingCompany,
                    service24RecoveryTowingContactName: data.service24RecoveryTowingContactName,
                    service24RecoveryTowingPhone: data.service24RecoveryTowingPhone
                }));
                setJobChecks((st) => ({
                    ...st,
                    service24DateRepairCompleted: formatters.DateFormatter(data.service24DateRepairCompleted),
                    service24MaintenanceBookletChecked: data.service24MaintenanceBookletChecked || false,
                    service24EntitledToMobiloVanServices: data.service24EntitledToMobiloVanServices || false,
                    service24ReplacementVehicle: data.service24ReplacementVehicle || false,
                    service24BreakdownOrStartingAssistance: data.service24BreakdownOrStartingAssistance || false,
                    service24VehicleReturnWithin30Miles: data.service24VehicleReturnWithin30Miles || false,
                    service24AirTravel: data.service24AirTravel || null,
                    service24RailTravel: data.service24RailTravel || null,
                    service24Taxi: data.service24Taxi || null,
                    service24PublicTransport: data.service24PublicTransport || null,
                    service24Hotel: data.service24Hotel || null,
                    service24Notes: data.service24Notes
                }));
                //   const technicianSign = await resizeImage(data.service24TechnicianSignature);
                setRepairType((st) => ({
                    ...st,
                    service24TemporaryRepair: data.service24TemporaryRepair || false,
                    service24IncompleteRepair: data.service24IncompleteRepair || false,
                    service24TechnicianSignature: data.service24TechnicianSignature,
                    techSignature: data.service24TechnicianSignature,
                    service24TechnicianName: data.service24TechnicianName || '',
                    technicianName: data.service24TechnicianName || '',
                    service24OperatorSignature: data.service24OperatorSignature,
                    opSignature: data.service24OperatorSignature,
                    service24OperatorSignedByName: data.service24OperatorSignedByName || '',
                    operatorSignedByName: data.service24OperatorSignedByName || ''
                }));
                setOtherDetails((st) => ({
                    ...st,
                    service24VehicleMobile: formatters.DateFormatter(data.service24VehicleMobile),
                    service24CACAdvised: formatters.DateFormatter(data.service24CACAdvised)
                }));
                setDriversDetails((st) => ({
                    ...st,
                    service24DriverName: data.service24DriverName,
                    driverName: data.service24DriverName,
                    service24DriverPhone: data.service24DriverPhone,
                    service24DriverSignature: data.service24DriverSignature,
                    driverSignature: data.service24DriverSignature,
                    service24DriverDateSigned: data.service24DriverDateSigned || null
                }));
                setservice24PartsVM(
                    !res?.data?.service24Parts?.length
                        ? [
                              {
                                  service24PartID: null,
                                  service24PartNumber: '',
                                  service24PartDescription: '',
                                  service24PartQuantity: 0,
                                  service24PartPrice: null
                              }
                          ]
                        : res?.data?.service24Parts
                );
                setService24WorkDetailsVM(
                    !res?.data?.service24WorkDetails?.length
                        ? [
                              {
                                  service24WorkID: null,
                                  service24WorkRequested: '',
                                  service24WorkReason: '',
                                  service24WorkAction: null,
                                  service24WorkTimeTaken: null
                              }
                          ]
                        : res?.data?.service24WorkDetails
                );
                setControl((st) => ({
                    ...st,
                    service24PaymentTypeID: data.service24PaymentTypeID
                }));
                setService24TimesVM(
                    res?.data?.service24Times.length == 0
                        ? [
                              {
                                  service24TimeID: null,
                                  service24Name: '',
                                  service24DrivingStartTime: '',
                                  service24DrivingEndTime: '',
                                  service24RepairStartTime: '',
                                  service24RepairEndTime: '',
                                  service24ServiceVan: '',
                                  service24DrivingStartMileage: null,
                                  service24DrivingFinishMileage: null,
                                  service24FinishPostCode: '',
                                  service24StartPostCode: ''
                              }
                          ]
                        : res?.data?.service24Times
                );
            }
        }
    }, [vehDetails.service24JobID]);

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!customerDetails.service24CustomerName) {
            formIsValid = false;
            errors['service24CustomerName'] = 'Customer Name cannot be empty';
        }
        if (!repairType.service24TechnicianSignature) {
            formIsValid = false;
            errors['service24TechnicianSignatureErrorMsg'] = 'Technician Signature Is Required';
        }

        let list = [...service24TimesVM].map((m) => {
            let drivingStartTimeErrorMessage = '';
            let drivingEndTimeErrorMessage = '';
            let repairStartTimeErrorMessage = '';
            let repairEndTimeErrorMessage = '';
            let repairEndMilageMessage = '';
            let drivingStartMileageErrorMessage = '';

            // if (!m.service24DrivingStartTime) {
            //     drivingStartTimeErrorMessage = 'Driving Start Time is Required';
            //     formIsValid = false;
            // }

            // if (!m.service24DrivingEndTime) {
            //     drivingEndTimeErrorMessage = 'Driving End Time is Required';
            //     formIsValid = false;
            // }

            // if (!m.service24RepairStartTime) {
            //     repairStartTimeErrorMessage = 'Work Start Time is Required';
            //     formIsValid = false;
            // }

            // if (!m.service24RepairEndTime) {
            //     repairEndTimeErrorMessage = 'Work End Time is Required';
            //     formIsValid = false;
            // }
            if (m.service24DrivingFinishMileage) {
                if (!m.service24DrivingStartMileage) {
                    drivingStartMileageErrorMessage = 'Start Mileage is Required';
                    formIsValid = false;
                } else {
                    if (+m.service24DrivingStartMileage > +m.service24DrivingFinishMileage) {
                        repairEndMilageMessage = 'Finish Mileage should be greater than start Mileage';
                        formIsValid = false;
                    }
                }
            }

            return {
                ...m,
                drivingStartTimeErrorMessage,
                drivingEndTimeErrorMessage,
                repairStartTimeErrorMessage,
                repairEndTimeErrorMessage,
                repairEndMilageMessage,
                drivingStartMileageErrorMessage
            };
        });

        setService24TimesVM(list);
        setCustomerDetails((st) => ({ ...st, errors: errors }));
        setRepairType((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };
    const submithandler = async () => {
        if (handleValidation()) {
            let data = {
                service24JobID: vehDetails.service24JobID,
                service24JobCaseNo: basicHeaderDetails.service24JobCaseNo,
                service24JobHeaderRecordID: HeaderId,
                service24CustomerName: customerDetails.service24CustomerName || '',
                service24CustomerAddress1: customerDetails.service24CustomerAddress1 || '',
                service24CustomerAddress2: customerDetails.service24CustomerAddress2 || '',
                service24CustomerCity: customerDetails.service24CustomerCity || '',
                service24CustomerPostCode: customerDetails.service24CustomerPostCode || '',
                service24CustomerContactName: customerDetails.service24CustomerContactName || '',
                service24CustomerPhone: customerDetails.service24CustomerPhone || '',
                service24CustomerOrderNo: customerDetails.service24CustomerOrderNo || '',
                service24RegNumber: vehDetails.service24RegNumber,
                service24VehicleType: vehDetails.service24VehicleType,
                service24ChassisNo: vehDetails.service24ChassisNo,
                service24EngineNo: vehDetails.service24EngineNo,
                service24RegDate: vehDetails.service24RegDate,
                service24Colour: vehDetails.service24Colour,
                service24GVW: vehDetails.service24GVW,
                service24Load: vehDetails.service24Load,
                service24Destination: vehDetails.service24Destination,
                service24TrailerOwner: vehDetails.service24TrailerOwner,
                service24TrailerNo: vehDetails.service24TrailerNo,
                service24OdometerReading: vehDetails.service24OdometerReading,
                service24OdometerUnit: '', //service24OdometerUnit,not defined yet
                service24HomeAccountDealerName: homeAccDealer.service24HomeAccountDealerName,
                service24HomeAccountDealerContactName: homeAccDealer.service24HomeAccountDealerContactName,
                service24HomeAccountDealerOrderNo: homeAccDealer.service24HomeAccountDealerOrderNo,
                service24HomeAccountDealerLimit: homeAccDealer.service24HomeAccountDealerLimit || null,
                service24RentalCompany: rentalFleetDetails.service24RentalCompany || '',
                service24RentalPhone: rentalFleetDetails.service24RentalPhone || '',
                service24RentalContactName: rentalFleetDetails.service24RentalContactName || '',
                service24RentalOrderNo: rentalFleetDetails.service24RentalOrderNo || '',
                // service24BreakdownLocation: breakdownLocation.service24BreakdownLocation || '',
                // service24DefectDescription: defect.service24DefectDescription || '',
                // service24RepairDetails: repairsCarriedOut.service24RepairDetails || '',
                service24BreakdownLocation: breakdownDefectRepairDefails.service24BreakdownLocation || '',
                service24DefectDescription: breakdownDefectRepairDefails.service24DefectDescription || '',
                service24RepairDetails: breakdownDefectRepairDefails.service24RepairDetails || '',
                service24Recovered: recoveryDetails.service24Recovered || false,
                service24RecoveryStartLocation: recoveryDetails.service24RecoveryStartLocation,
                service24RecoveryEndLocation: recoveryDetails.service24RecoveryEndLocation,
                service24RecoveryDistance: recoveryDetails.service24RecoveryDistance,
                service24RecoveryTowingCompany: recoveryDetails.service24RecoveryTowingCompany,
                service24RecoveryTowingContactName: recoveryDetails.service24RecoveryTowingContactName,
                service24RecoveryTowingPhone: recoveryDetails.service24RecoveryTowingPhone,
                service24DateRepairCompleted: jobChecks.service24DateRepairCompleted || null,
                service24MaintenanceBookletChecked: jobChecks.service24MaintenanceBookletChecked || false,
                service24EntitledToMobiloVanServices: jobChecks.service24EntitledToMobiloVanServices || false,
                service24ReplacementVehicle: jobChecks.service24ReplacementVehicle || false,
                service24BreakdownOrStartingAssistance: jobChecks.service24BreakdownOrStartingAssistance || false,
                service24VehicleReturnWithin30Miles: jobChecks.service24VehicleReturnWithin30Miles || false,
                service24AirTravel: jobChecks.service24AirTravel || null,
                service24RailTravel: jobChecks.service24RailTravel || null,
                service24Taxi: jobChecks.service24Taxi || null,
                service24PublicTransport: jobChecks.service24PublicTransport || null,
                service24Hotel: jobChecks.service24Hotel || null,
                service24Notes: jobChecks.service24Notes,
                service24TemporaryRepair: repairType.service24TemporaryRepair || false,
                service24IncompleteRepair: repairType.service24IncompleteRepair || false,
                service24TechnicianSignature: repairType.service24TechnicianSignature,
                service24TechnicianName: repairType.service24TechnicianName || '',
                service24OperatorSignature: repairType.service24OperatorSignature,
                service24OperatorSignedByName: repairType.service24OperatorSignedByName || '',
                service24VehicleMobile: otherDetails.service24VehicleMobile || null,
                service24CACAdvised: otherDetails.service24CACAdvised || null,
                service24Status: '', // this is under part modal service24Status,
                service24PaymentTypeID: control.service24PaymentTypeID,
                service24DriverName: driversDetails.service24DriverName,
                service24DriverPhone: driversDetails.service24DriverPhone,
                service24DriverSignature: driversDetails.service24DriverSignature,
                service24DriverDateSigned: driversDetails.service24DriverDateSigned || null,
                service24PartsVM: service24PartsVM,
                service24WorkDetailsVM: service24WorkDetailsVM,
                service24TimeVM: service24TimesVM.map((m) => ({
                    service24TimeID: m.service24TimeID,
                    service24Name: m.service24Name,
                    service24DrivingStartTime: m.service24DrivingStartTime,
                    service24DrivingEndTime: m.service24DrivingEndTime,
                    service24RepairStartTime: m.service24RepairStartTime,
                    service24RepairEndTime: m.service24RepairEndTime,
                    service24ServiceVan: m.service24ServiceVan,
                    service24DrivingStartMileage: m.service24DrivingStartMileage,
                    service24DrivingFinishMileage: m.service24DrivingFinishMileage,
                    service24FinishPostCode: m.service24FinishPostCode,
                    service24StartPostCode: m.service24StartPostCode
                }))
            };

            let res = await postService24(data);
            if (!res.success) {
                console.error(res);
                showToast(res.title ? res.title : 'Error Occurred');
            } else {
                hideModal();
                showToast(res.message);
            }
            if (res.success && fromStep) {
                window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
            }
        }
    };

    const handelClose = () => {
        window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
    };

    const RenderRecoveryHeader = () => {
        return (
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item>Recovery Details</Grid>
                <Grid item>
                    <ButtonGroup disableElevation variant="contained" color="secondary" size="small">
                        <Button variant={recoveryDetails.service24Recovered ? 'contained' : 'outlined'} onClick={() => handleYesNo('service24Recovered', true)}>
                            Yes
                        </Button>

                        <Button
                            variant={recoveryDetails.service24Recovered ? 'outlined' : 'contained'}
                            onClick={() => handleYesNo('service24Recovered', false)}
                        >
                            No
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        );
    };
    return (
        <Grid container>
            {!vehDetails.showCircularLoader ? (
                <Grid container item sm={12} alignItems="center" justify="center" style={{ width: 'calc(100vh - 230px)', height: 640 }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    {/* {showPartDialog && (
                        <PartDialog
                            onClose={hidePartDialog}
                            part={service24PartsVM}
                            setPart={setservice24PartsVM}
                            work={service24WorkDetailsVM}
                            setWork={setService24WorkDetailsVM}
                            control={control}
                            setControl={setControl}
                        />
                    )} */}

                    <Grid container style={{ padding: '0px 16px 0px 16px' }}>
                        <Grid item container spacing={2}>
                            <Grid item xs={8} style={{ fontSize: 21, textAlign: 'left' }}>
                                Reg. No:&nbsp; <b> {vehDetails?.registration} </b>&nbsp; WIP No:&nbsp; <b> {vehDetails?.wipNumber}</b>
                            </Grid>
                            <Grid item xs={4}>
                                <TextBoxShrink
                                    onChange={setField}
                                    value={basicHeaderDetails.service24JobCaseNo}
                                    label="CAC Case No"
                                    name="service24JobCaseNo"
                                />
                            </Grid>
                            {/* <Grid item xs={1}>
                                <PrimaryButton fullWidth onClick={() => setShowPartDialog(true)} style={{ marginTop: 8 }}>
                                    Parts
                                </PrimaryButton>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <FieldWrappper heading="Customer Details" component={CustDetails} setState={setCustomerDetails} state={customerDetails} />
                    <FieldWrappper heading="Home Account Dealer" component={HomeAccDealer} setState={setHomeAccDealer} state={homeAccDealer} />
                    <FieldWrappper heading="Rental Fleet Details" component={RentalFleetDetails} setState={setRentalFleetDetails} state={rentalFleetDetails} />
                    <FieldWrappper heading="Vehicle Details" component={VehicleDetails} setState={setVehDetails} state={vehDetails} />
                    {/* <FieldWrappper heading="Breakdown Location" component={BreakdownLocation} setState={setBreakdownLocation} state={breakdownLocation} />
                    <FieldWrappper heading="Defect" component={DefectSection} setState={setDefect} state={defect} />
                    <FieldWrappper heading="Repairs Carried Out" component={RepairsCarriedOut} setState={setRepairsCarriedOut} state={repairsCarriedOut} /> */}
                    <FieldWrappper
                        heading=""
                        component={BreakdownDefectRepair}
                        setState={setBreakdownDefectRepairDefails}
                        state={breakdownDefectRepairDefails}
                    />
                    <FieldWrappper heading={RenderRecoveryHeader()} component={RecoveryDetails} setState={setRecoveryDetails} state={recoveryDetails} />
                    <FieldWrappper heading="Job Checks" component={JobChecks} setState={setJobChecks} state={jobChecks} />
                    <FieldWrappper heading="Traveling Time" isAccordion={true}>
                        <ServiceTime component={ServiceTime} service24={service24TimesVM} setService24={setService24TimesVM} />
                    </FieldWrappper>
                    <FieldWrappper isAccordion={true} heading="Parts">
                        <PartDialog part={service24PartsVM} setPart={setservice24PartsVM} control={control} />
                    </FieldWrappper>
                    <FieldWrappper isAccordion={true} heading="Work Requested">
                        <WorkRequestedModal work={service24WorkDetailsVM} setWork={setService24WorkDetailsVM} control={control} />
                    </FieldWrappper>
                    <FieldWrappper heading="Invoicing Details">
                        <Grid item xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <RadioGroup
                                    row
                                    aria-label="paymentType"
                                    name="service24PaymentTypeID"
                                    value={control.service24PaymentTypeID}
                                    onChange={handleInput}
                                >
                                    {control.service24PaymentTypes.map((n) => {
                                        return <FormControlLabel value={n.id} control={<Radio />} label={n.name} />;
                                    })}
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </FieldWrappper>
                    <FieldWrappper heading="Repair" component={RepairType} setState={setRepairType} state={repairType} />
                    <FieldWrappper heading="Others" component={BasicFooter} setState={setOtherDetails} state={otherDetails} />
                    <FieldWrappper heading="Driver's" component={DriverDetails} setState={setDriversDetails} state={driversDetails} />
                    <Box m={2}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <GreenButton fullWidth variant="contained" color="secondary" onClick={submithandler} disabled={isAuditor}>
                                    save
                                </GreenButton>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SecondaryButton fullWidth onClick={handelClose}>
                                    Cancel
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

export default Service24Modal;
