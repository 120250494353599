import React, { useMemo } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
let addCommas = (val) => {
    return val ? `£${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
};
const baseColumns = [
    { field: 'salesExec', sortable: false, title: `Sales Exec`, filterable: false },
    {
        field: 'totalAmount',
        title: 'Total',
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false,
        isCurrency: true
    },
    {
        field: 'sevenDays',
        title: '7 Days',
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false
    },
    {
        field: 'fourteenDays',
        title: '8-14 Days',
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false
    },
    {
        field: 'twentyOneDays',
        title: '15-21 Days',
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false
    },
    {
        field: 'overTwentyOneDays',
        title: 'Over 21 Days',
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false
    }
];
const crumbs = (measuredesc) => [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'Vehicle Aged Debt By Salesexec', active: true }
];
const VehicleAgedDebtBySalesexec = (props) => {
    const history = useHistory();
    const redirectToScreens = (value, record, MeasureID, field) => {
        let searchQuery = `?SalesExecCode=${record.salesExecCode}&AgeDays=${field.AgeDays}&salesExec=${record.salesExec}`;
        history.push({
            pathname: '/sales/ViewDebtDetailsSalesexec',
            search: searchQuery
        });
    };

    const monthsActionHandler = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                title={field.toolTipLabel ? record[field.toolTipLabel] : value}
                onClick={() => redirectToScreens(value, record, props.MeasureID, field)}
                style={{ cursor: 'pointer' }}
            >
                {field.AgeDays ? addCommas(value) : value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];

        let salesExecCol = tempCols.find((element) => element.field === 'salesExec');
        let sevenDaysCol = tempCols.find((element) => element.field === 'sevenDays');
        let fourteenDaysCol = tempCols.find((element) => element.field === 'fourteenDays');
        let twentyOneDaysCol = tempCols.find((element) => element.field === 'twentyOneDays');
        let overTwentyOneDaysCol = tempCols.find((element) => element.field === 'overTwentyOneDays');

        if (salesExecCol) {
            salesExecCol.renderer = monthsActionHandler({ AgeDays: '' });
        }
        if (sevenDaysCol) {
            sevenDaysCol.renderer = monthsActionHandler({ AgeDays: 7 });
        }
        if (fourteenDaysCol) {
            fourteenDaysCol.renderer = monthsActionHandler({ AgeDays: 14 });
        }
        if (twentyOneDaysCol) {
            twentyOneDaysCol.renderer = monthsActionHandler({ AgeDays: 21 });
        }
        if (overTwentyOneDaysCol) {
            overTwentyOneDaysCol.renderer = monthsActionHandler({ AgeDays: 22 });
        }

        return tempCols;
    }, []);

    const baseUrl = `Sales/VehicleDebt`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs()} />
            <Grid item container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isShowTotal={true}
                        hidePagination={true}
                        hideFilterRow={true}
                        downloadName="Vehicle Aged Debt By Salesexec"
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default VehicleAgedDebtBySalesexec;
