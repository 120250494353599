import React from 'react';
import ReactDOM from 'react-dom';
import './App/index.css';
import App from './App/App.jsx';
import reportWebVitals from './App/reportWebVitals';
import './App/i18n';
import PackageJson from '../package.json';
import { AppStorage } from './Core/Service/storage-service';

const DevApp = () => {
    return (
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
};

const ProdApp = () => {
    return <App />;
};

window._ENV_ = Object.assign(window._ENV_ || {}, { app_version: PackageJson.version });
if (AppStorage.isLoggedIn() && window._ENV_.app_version !== AppStorage.getLoggedInUIVersion()) {
    AppStorage.performLogout();
}

ReactDOM.render(process.env.NODE_ENV === 'production' ? <ProdApp /> : <DevApp />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
