import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { grid } from 'gijgo';
import { AppStorage } from './../../../Core/Service/storage-service';
import '../Dashboard/dashboard.scss';
import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ExpBaseURL } from './../../../Core/Service/http-calls';
import moment from 'moment';

const AuditorJobDetails = (props) => {
    const token = AppStorage.getToken();
    useEffect(async () => {
        if (token) {
            pullJobDetailsAndUpdateState();
        } else {
            window.location = '/';
            AppStorage.performLogout();
        }
    }, [token]);
    const pullJobDetailsAndUpdateState = async () => {
        const onSuccessFunc = function (response) {
            var obj = {
                records: response.data.rows,
                total: response.data.total
            };
            grid1.render(obj);
        };
        const handleBtnClcik = (val) => {
            props.history.push(`/Job/${val}/7`);
        };
        const editButton = (vl) => {
            const spn = document.createElement('span');
            const lnk = (
                <div onClick={()=>{handleBtnClcik(vl)} }  className="px-10">
                    <IconButton size="small">
                        <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                </div>
            );
            ReactDOM.render(lnk, spn);
            return spn;
        };

        const GridID = props.match.params.id;
        const SelectedPireod = props.match.params.name;

        var grid1 = window.$('#grid').grid({
            primaryKey: 'Id',
            dataSource: {
                url: `${ExpBaseURL}/api/AuditorJobs?ShowRecord=${GridID}`,
                headers: { Authorization: `Bearer ${token}` },
                success: onSuccessFunc
            },
            headerFilter: true,
            columns: [
                {
                    title: 'Action',
                    field: 'id',
                    renderer: editButton,
                    width: 20,
                    filterable: false
                },
                {
                    title: 'WIP Number',
                    field: 'wipNo',
                    width: 100,
                    sortable: true
                },
                {
                    title: 'Vehicle Reg',
                    field: 'registration',
                    width: 100,
                    sortable: true
                },
                {
                    field: 'dateDueIn',
                    sortable: true,
                    title: 'Due Date In',
                    type: 'dateTime',
                    width: 135,
                    renderer: function (value, record) {
                        if (record.dateDueIn != null) {
                            return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
                        }
                        return '-';
                    }
                },
                {
                    title: 'Model',
                    field: 'makeModel',
                    width: 100,
                    sortable: true
                },
                {
                    title: 'Branch',
                    field: 'branchShortName',
                    width: 100,
                    sortable: true
                }
            ].map((c) => {
                if (c.title !== 'Action') {
                    c.events = {
                        click: function (e) {
                            props.history.push(`/Job/${e.data.record.headerRecordID}/7`);
                        }
                    };
                }
                return {
                    ...c,
                    headerCssClass: 'gridHeader'
                };
            }),
            pager: { limit: 10 }
        });
    };
    return (
        <div >
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    {props.match.params.name}
                </Typography>
            </Breadcrumbs>
            <Grid container className="common-gijgo-grid" style={{ margin: '10px auto' }}>
                <Grid item xs={12} sm={12}>
                    <table id="grid" style={{ width: '99%' }}></table>
                </Grid>
            </Grid>
        </div>
    );
};
export default AuditorJobDetails;
