import React, { useEffect, useContext, useState } from 'react';
import { TextBox, DialogContents, DialogTitles, Dialogs, SecondaryButton, DialogsActions, SecondaryCheckbox } from '../../../Core/FormInput';
import Grid from '@material-ui/core/Grid';
import { getGridFilterList, postGridFilterList } from '../../../Core/Service/workflow-service';
import AppContext from '../../../App/AppContext';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { find, some } from 'lodash';
import Switch from '@material-ui/core/Switch';
import { MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

const initState = {};
let GridFilterModal = (props) => {
    const { showToast, setUserPreference } = useContext(AppContext);

    const [state, setState] = useState({
        filterList: [],
        languageList: [],
        languageCode: {}
    });

    useEffect(() => {
        Object.values(props.filterList).forEach((obj) => {
            initState[obj.fieldName] = obj.hidden;
        });
        setState((st) => ({ ...st, fieldValues: { ...initState }, filterList: props.filterList }));

        let data;
        Object.values(props?.languageData).forEach((obj) => {
            if (obj.id == props?.languageCode) {
                data = obj;
            }
        });
        setState((st) => ({ ...st, languageCode: data }));
    }, [props.filterList]);

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            let newSt = { ...st };
            newSt.fieldValues[name] = checked;
            return newSt;
        });
    };

    const handelClose = () => {
        props.handleCancel(false);
    };

    const handleSubmit = async () => {
        let data = { ...state.fieldValues, UserGridColumnLanguageCode: state.languageCode?.id };
        let res = await postGridFilterList(data);
        if (res.success) {
            await setUserPreference();
            props.handleCancel(true);
        } else {
            showToast(res.message);
        }
    };

    const handleAutoChange = (e, value, comp) => {
        if (value) {
            if (comp == 'language') {
                setState((st) => {
                    let newSt = { ...st };
                    newSt.languageCode = value;
                    return newSt;
                });
            }
        }
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    Selected Grid Columns
                </Grid>

                {state.filterList.map((j) => {
                    if (j.field === 'pagination') {
                        return (
                            <Grid container xs={4} item component="label" className="childSwitchBtnContainer" alignItems="center">
                                <Grid item>Pagination</Grid>
                                <Grid item>
                                    <Switch checked={state.fieldValues[j.fieldName]} onChange={handleCheckbox} value="checked" name={j.fieldName} />
                                </Grid>
                            </Grid>
                        );
                    }
                    return (
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                control={<SecondaryCheckbox checked={state.fieldValues[j.fieldName]} onChange={handleCheckbox} name={j.fieldName} />}
                                label={j.title}
                            />
                        </Grid>
                    );
                })}

                <Grid item xs={4} style={{ marginTop: '-8px' }}>
                    <FormLabel>Language</FormLabel>
                    <Autocomplete
                        autoSelect
                        options={props.languageData}
                        name="language"
                        value={state.languageCode}
                        onChange={(e, value) => handleAutoChange(e, value, 'language')}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                        renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                    />
                </Grid>

                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={6}>
                        <SecondaryButton onClick={handleSubmit} fullWidth className="submit_btn">
                            Submit
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={6}>
                        <SecondaryButton onClick={handelClose} fullWidth className="submit_btn">
                            Cancel
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default GridFilterModal;
