import {
  Container,
  Grid,
  Avatar,
  Typography,
  Button,
  ButtonGroup,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SpeedIcon from "@material-ui/icons/Speed";
import { useHistory } from "react-router-dom";
import { getReprtingDashboard } from "../../../Core/Service/ReportScreens-service";
import { SmallLinkedButton } from "./ReportsScreens/BudgetCore/budgetButton";
import formatters from "../../../Core/Grid/inputFormatter";
import "./ReportingScreensStyle.scss";
import Dashboard from "./../dashboard/dashboard";
import { forEach } from "lodash";
import { BorderRight } from "@material-ui/icons";
import { SecondaryButton } from "../../../Core/FormInput";
import { StockCard, VehicleCard } from "./saleDashboardCard";
// import { Typography  from '@material-ui/core/Typography';

const reportButton = [
  {
    name: "New Vehicle",
    id: 1,
  },
  {
    name: "Used Vehicle",
    id: 2,
  },
  {
    name: "Registrations",
    id: 3,
  },
  {
    name: "New Vehicle Stock",
    id: 4,
  },
  {
    name: "Used Vehicle Stock",
    id: 5,
  },
  {
    name: "Aged Stock",
    id: 6,
  },
];

const tilte = {
  new: "New Vehicle",
  used: "Used Vehicle",
  reg: "Registrations",
  ns: "New Vehicle Stock",
  US: "Used Vehicle Stock",
  AS: "Aged Stock",
};

const ReportingDashboard = () => {
  const history = useHistory();

  const RedirectToDash = (val) => {
    history.push({
      pathname: val,
    });
  };
  const [state, setState] = useState({
    // newVehicle: [],
    // usedVehicle: [],
    // registrations: [],
    // newVehicleStock: [],
    // usedVehicleStock: [],
    // agedStock: [],
    // buttonID: reportButton[0].id,
    newVehiclesumofytd: 0,
    newVehiclesumofmtd: 0,
    usedVehiclesumofytd: 0,
    usedVehiclesumofmtd: 0,
    registrationssumofytd: 0,
    registrationssumofmtd: 0,
    newVehicleStocksumofytd: 0,
    newVehicleStocksumofmtd: 0,
    usedVehicleStocksumofytd: 0,
    usedVehicleStocksumofmtd: 0,
    agedStocksumofytd: 0,
    agedStocksumofmtd: 0,
    registrationsFranchiseArr: [],
    usedVehicleFranchiseArr: [],
    newVehicleStockFranchiseArr: [],
    newVehicleFranchiseArr: [],
    usedVehicleStockFranchiseArr: [],
    agedStockFranchiseArr: [],
    isRelod: true,
  });

  useEffect(async () => {
    let res = await getReprtingDashboard();

    let newVehicle = [...res.data?.newVehicle];
    let usedVehicle = [...res.data?.usedVehicle];
    let registrations = [...res.data?.registrations];
    let newVehicleStock = [...res.data?.newVehicleStock];
    let usedVehicleStock = [...res.data?.usedVehicleStock];
    // let agedStock = [...res.data?.agedStock];

    if (res.success) {
      //new Vehivle
      if (newVehicle.length > 0) {
        let newVehiclesumofytd = 0;
        let newVehiclesumofmtd = 0;
        let newVehiclesumofBudgetmtd = 0;
        let newVehiclesumofBudgetYtd = 0;
        let totalCount = [...newVehicle].forEach(function (item) {
          newVehiclesumofytd += item.ytdTotal;
          newVehiclesumofmtd += item.mtdTotal;
          newVehiclesumofBudgetmtd += item.mtdTotalBudget;
          newVehiclesumofBudgetYtd += item.ytdTotalBudget;
        });

        var newVehicleFranchiseArr = newVehicle.reduce((acc, obj) => {
          var index = acc.find(
            (item) => item.franchiseName === obj.franchiseName
          );
          if (index) {
            index.mtdTotal += obj.mtdTotal;
            index.ytdTotal += obj.ytdTotal;
            return acc;
          }
          acc.push({ ...obj });
          return acc;
        }, []);
        setState((st) => ({
          ...st,
          newVehiclesumofytd: newVehiclesumofytd,
          newVehiclesumofmtd: newVehiclesumofmtd,
          newVehicleFranchiseArr: newVehicleFranchiseArr,
          newVehiclesumofBudgetmtd: newVehiclesumofBudgetmtd,
          newVehiclesumofBudgetYtd: newVehiclesumofBudgetYtd,
          isRelod: false,
        }));
      }
      //Used Vehicle
      if (usedVehicle.length > 0) {
        let usedVehiclesumofytd = 0;
        let usedVehiclesumofmtd = 0;
        let usedVehiclesumofBudgetmtd = 0;
        let usedVehiclesumofBudgetYtd = 0;
        let totalCount = [...usedVehicle].forEach(function (item) {
          usedVehiclesumofytd += item.ytdTotal;
          usedVehiclesumofmtd += item.mtdTotal;
          usedVehiclesumofBudgetmtd += item.mtdBudget;
          usedVehiclesumofBudgetYtd += item.ytdBudget;
        });

        var usedVehicleFranchiseArr = usedVehicle.reduce((acc, obj) => {
          var index = acc.find(
            (item) => item.franchiseName === obj.franchiseName
          );
          if (index) {
            index.mtdTotal += obj.mtdTotal;
            index.ytdTotal += obj.ytdTotal;
            return acc;
          }
          acc.push({ ...obj });
          return acc;
        }, []);
        setState((st) => ({
          ...st,
          usedVehiclesumofytd: usedVehiclesumofytd,
          usedVehiclesumofmtd: usedVehiclesumofmtd,
          usedVehicleFranchiseArr: usedVehicleFranchiseArr,
          usedVehiclesumofBudgetmtd: usedVehiclesumofBudgetmtd,
          usedVehiclesumofBudgetYtd: usedVehiclesumofBudgetYtd,
        }));
      }
      //Registrations

      if (registrations.length > 0) {
        let registrationssumofytd = 0;
        let registrationssumofmtd = 0;
        let registrationBudgetmtd = 0;
        let registrationBudgetYtd = 0;
        let totalCount = [...registrations].forEach(function (item) {
          registrationssumofytd += item.ytdTotal;
          registrationssumofmtd += item.mtdTotal;
          registrationBudgetmtd += item.mtdBudget;
          registrationBudgetYtd += item.ytdBudget;
        });

        var registrationsFranchiseArr = registrations.reduce((acc, obj) => {
          var index = acc.find(
            (item) => item.franchiseName === obj.franchiseName
          );
          if (index) {
            index.mtdTotal += obj.mtdTotal;
            index.ytdTotal += obj.ytdTotal;
            return acc;
          }
          acc.push({ ...obj });
          return acc;
        }, []);
        setState((st) => ({
          ...st,
          registrationssumofytd: registrationssumofytd,
          registrationssumofmtd: registrationssumofmtd,
          registrationsFranchiseArr: registrationsFranchiseArr,
          registrationBudgetmtd: registrationBudgetmtd,
          registrationBudgetYtd: registrationBudgetYtd,
        }));
      }

      //newVehicleStock
      if (newVehicleStock.length > 0) {
        let newVehicleStocksumofytd = 0;
        let newVehicleStocksumofmtd = 0;
        let totalCount = [...newVehicleStock].forEach(function (item) {
          newVehicleStocksumofytd += item.ytdTotal;
          newVehicleStocksumofmtd += item.mtdTotal;
        });

        var newVehicleStockFranchiseArr = newVehicleStock.reduce((acc, obj) => {
          var index = acc.find(
            (item) => item.franchiseName === obj.franchiseName
          );
          if (index) {
            index.mtdTotal += obj.mtdTotal;
            index.ytdTotal += obj.ytdTotal;
            return acc;
          }
          acc.push({ ...obj });
          return acc;
        }, []);
        setState((st) => ({
          ...st,
          newVehicleStocksumofytd: newVehicleStocksumofytd,
          newVehicleStocksumofmtd: newVehicleStocksumofmtd,
          newVehicleStockFranchiseArr: newVehicleStockFranchiseArr,
        }));
      }
      //usedVehicleStock
      if (usedVehicleStock.length > 0) {
        setState((st) => ({
          ...st,
          usedVehicleStock: usedVehicleStock,
        }));
      }
    }
  }, []);
  return (
    <div className="reporting-section">
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            height: "calc(100vh - 190px)",
            background: "#f5f5f5",
            overflow: "auto",
            padding: 10,
          }}
          className="custom-scroll"
        >
          {!state.isRelod ? (
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={4}
                style={{ padding: 10 }}
              >
                <VehicleCard
                  MTD={state.newVehiclesumofmtd}
                  YTD={state.newVehiclesumofytd}
                  row={state.newVehicleFranchiseArr || []}
                  BudgetMTD={state.newVehiclesumofBudgetmtd}
                  BudgetYTD={state.newVehiclesumofBudgetYtd}
                  label="New Vehicle"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={4}
                style={{ padding: 10 }}
              >
                <VehicleCard
                  MTD={state.usedVehiclesumofmtd}
                  YTD={state.usedVehiclesumofytd}
                  row={state.usedVehicleFranchiseArr || []}
                  BudgetMTD={state.usedVehiclesumofBudgetmtd}
                  BudgetYTD={state.usedVehiclesumofBudgetYtd}
                  label="Used Vehicle"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={4}
                style={{ padding: 10 }}
              >
                <VehicleCard
                  MTD={state.registrationssumofmtd}
                  YTD={state.registrationssumofytd}
                  row={state.registrationsFranchiseArr || []}
                  BudgetMTD={state.registrationBudgetmtd}
                  BudgetYTD={state.registrationBudgetYtd}
                  label="Registrations"
                />
              </Grid>
              {/* <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={4}
                style={{ padding: 10 }}
              >
                <VehicleCard
                  MTD={state.newVehicleStocksumofmtd}
                  YTD={state.newVehicleStocksumofytd}
                  row={state.newVehicleStockFranchiseArr || []}
                  label="New Vehicle Stock"
                />
              </Grid> */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: 10 }}
              >
                <StockCard
                  currnetStock={state.usedVehiclecurrnetStock}
                  StockCount={state.usedVehicleStockCount}
                  Avrage={state.usedVegicleAvrage}
                  row={state.usedVehicleStock || []}
                  stock={true}
                  label="Used Vehicle Stock"
                />
              </Grid>
            </Grid>
          ) : (
            <div
              style={{
                height: "calc(100vh - 200px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          )}
          {/* <div className="mainHeading">{tilte[state.buttonID]}</div> */}
          {/* {state.newVehicle.map((m) => {
                        return (
                            <>
                                <Grid item xs={12} className="ReportingCard">
                                    <div className="headingOne">{m.branch || 'N / A'}</div>

                                    <Grid item container spacing={1}>
                                        <Grid item xs={2}>
                                            <Grid container className="kpibox" alignContent="flex-start">
                                                <Grid item xs={12} >
                                                    <div className="headingTwo">New Vehicle</div>
                                                </Grid>
                                                {m.data.map((n) => {
                                                    if (n.type === 'new') {
                                                        return (
                                                            <Grid
                                                            item
                                                            container
                                                           
                                                            xs={12}
                                                            style={{
                                                                marginTop: 10,
                                                                // borderBottom: '1px solid rgba(0,0,0,0.08)'
                                                            }}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption" color="secondary">
                                                                    {n.franchiseName || 'N/A'}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6} >
                                                                <div className="headingTwo">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(n.mtdTotal)}
                                                                </div>
                                                                <div className="headingThree">MTD</div>
                                                            </Grid>

                                                            <Grid item xs={6} >
                                                                <div className="headingTwo">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(n.ytdTotal)}
                                                                </div>
                                                                <div className="headingThree">YTD</div>
                                                            </Grid>
                                                        </Grid>
                                                        );
                                                    }
                                                })}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Grid container className="kpibox" alignContent="flex-start">
                                                <Grid item xs={12} >
                                                    <div className="headingTwo">Used Vehicle</div>
                                                </Grid>
                                                {m.data.map((n) => {
                                                    if (n.type === 'used') {
                                                        return (
                                                            <Grid
                                                            item
                                                            container
                                                           
                                                            xs={12}
                                                            style={{
                                                                marginTop: 10,
                                                                // borderBottom: '1px solid rgba(0,0,0,0.08)'
                                                            }}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption" color="secondary">
                                                                    {n.franchiseName || 'N/A'}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6} >
                                                                <div className="headingTwo">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(n.mtdTotal)}
                                                                </div>
                                                                <div className="headingThree">MTD</div>
                                                            </Grid>

                                                            <Grid item xs={6} >
                                                                <div className="headingTwo">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(n.ytdTotal)}
                                                                </div>
                                                                <div className="headingThree">YTD</div>
                                                            </Grid>
                                                        </Grid>
                                                        );
                                                    }
                                                })}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Grid container className="kpibox" alignContent="flex-start">
                                                <Grid item xs={12} >
                                                    <div className="headingTwo">Registrations</div>
                                                </Grid>
                                                {m.data.map((n) => {
                                                    if (n.type === 'reg') {
                                                        return (
                                                            <Grid
                                                            item
                                                            container
                                                        //    spacing={1}
                                                            xs={12}
                                                            // justifyContent="space-evenly"
                                                            style={{
                                                                // marginTop: 10,
                                                                // borderBottom: '1px solid rgba(0,0,0,0.08)'
                                                            }}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption" color="secondary">
                                                                    {n.franchiseName || 'N/A'}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6} >
                                                                <div className="headingTwo">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(n.mtdTotal)}
                                                                </div>
                                                                <div className="headingThree">MTD</div>
                                                            </Grid>

                                                            <Grid item xs={6} >
                                                                <div className="headingTwo">
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(n.ytdTotal)}
                                                                </div>
                                                                <div className="headingThree">YTD</div>
                                                            </Grid>
                                                        </Grid>
                                                        );
                                                    }
                                                })}
                                            </Grid>
                                        </Grid>

                                       
                                    </Grid>
                                </Grid>
                            </>
                        );
                    })} */}
        </Grid>
      </Grid>
      {/* <Grid container style={{ width: '40%', marginLeft: 'auto', marginRight: 'auto', marginTop: 50 }} spacing={5}>
                <Grid item xs={12} sm={12} md={6}>
                    <div
                        style={{
                            height: 200,
                            width: 200,
                            background: '#95a5a6',
                            backgroundImage: 'conic-gradient( #5e7273, #748687,#95a5a6)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            position: 'relative',
                            right: 0
                        }}
                        onClick={() => RedirectToDash('/AgedMeasures')}
                    >
                        <div style={{ color: 'white' }}>
                            <SpeedIcon style={{ fontSize: 60 }} />
                            <div style={{ fontSize: 18, fontWeight: 500, fontFamily: `"Public Sans", sans-serif` }}>Aged Measures</div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div
                        style={{
                            height: 200,
                            width: 200,
                            background: '#9c8061',
                            backgroundImage: 'conic-gradient( #9c8061,#82684a,#6e5538)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => RedirectToDash('/service/dashboard')}
                    >
                        <div style={{ color: 'white' }}>
                            <SpeedIcon style={{ fontSize: 60 }} />
                            <div style={{ fontSize: 18, fontWeight: 500, fontFamily: `"Public Sans", sans-serif` }}>Service Dashboard</div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div
                        style={{
                            height: 200,
                            width: 200,
                            background: '#2ecc71',
                            backgroundImage: 'conic-gradient( #0d6e36,#1ba656,#2ecc71)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => RedirectToDash('/sales/dashboard')}
                    >
                        <div style={{ color: 'white' }}>
                            <SpeedIcon style={{ fontSize: 60 }} />
                            <div style={{ fontSize: 18, fontWeight: 500, fontFamily: `"Public Sans", sans-serif` }}>Sales Dashboard v1 </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div
                        style={{
                            height: 200,
                            width: 200,
                            background: '#2ecc71',
                            backgroundImage: 'conic-gradient( #2ecc71,#1ba656,#0d6e36)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => RedirectToDash('/sales/dashboardtwo')}
                    >
                        <div style={{ color: 'white' }}>
                            <SpeedIcon style={{ fontSize: 60 }} />
                            <div style={{ fontSize: 18, fontWeight: 500, fontFamily: `"Public Sans", sans-serif` }}>Sales Dashboard V2</div>
                        </div>
                    </div>
                </Grid>
            </Grid> */}
    </div>
  );
};

export default ReportingDashboard;
