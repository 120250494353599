import React, { useEffect, useState } from 'react';
import { getSpecialEquipmentDash } from '../../../Core/Service/SpecialEquipmentService';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import '../../../Core/Dashbord/mainDashboard.scss';
import { getBranches } from '../../../Core/Service/branches-service';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 3,
        border: '2px solid #f5f5f5 ',
        Opacity: 0.9,
        minWidth: 120,
        padding: '5px 0px',
        background: '#f5f5f5',
        borderRadius: 10
    }
}));

const SpecialEquipmentDash = (props) => {
    const classes = useStyles();

    const [state, setState] = useState({
        selectedBranch: 'Blackburn'
    });

    const pullDashData = async () => {
        const res = await getSpecialEquipmentDash();

        if (res.success) {
            const { equipment, equipmentByBranch, equipmentInUse } = res.data;
            setState((st) => ({
                ...st,
                equipment: res.data.equipment[0],
                equipmentByBranch: res.data.equipmentByBranch,
                equipmentInUse: res.data.equipmentInUse,
                equipmentTotalUse: res.data.equipmentTotalUse
            }));
        }
    };

    const pullBranches = async () => {
        const res = await getBranches();
        if (res.success) {
            setState((st) => ({
                ...st,
                branches: res.data
            }));
        }
    };

    useEffect(() => {
        pullBranches();
        pullDashData();
    }, []);

    const handleBranchChange = (e) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.selectedBranch = e.target.value;
            return newSt;
        });
    };

    let history = useHistory();

    const setWindow = (page, branch, equipmentID, equipmentCode) => {
        if (page == 'equipment') {
            history.push('/Lists/SpecialEquipment', {
                page: page,
                branch: branch,
                equipmentID: equipmentID,
                equipmentCode: equipmentCode
            });
        } else {
            let searchQuery = `id=${equipmentID}&code=${equipmentCode}&activeOnly=${page}`;
            history.push({
                pathname: `/SpecialEquipmentHistoryModal`,
                search: searchQuery
            });
        }
    };

    return (
        <div className="mainDashboard-container">
            <Grid item container alignContent="flex-start" style={{ overflow: 'auto', height: 'calc(100vh - 110px)' }} className="custom-scroll">
                <div item style={{ padding: `0px 5px 0px 10px` }}>
                    <Card
                        className={classes.root}
                        style={{
                            background: 'white',
                            color: 'black',
                            padding: 0
                        }}
                        onClick={() => {
                            window.location = `/Lists/SpecialEquipment`;
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'left',
                                cursor: 'pointer',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                padding: '5px 20px'
                            }}
                        >
                            <span style={{ fontSize: 14, marginBottom: 6 }}>Active Equipments</span>
                            <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>{state.equipment?.equipmentCount}</div>
                        </div>
                    </Card>
                </div>
                <div item style={{ padding: `0px 5px 0px 10px` }}>
                    <Card
                        className={classes.root}
                        style={{
                            background: 'white',
                            color: 'black',
                            padding: 0
                        }}
                        //change
                        onClick={() => setWindow(true, null, null, null)}
                    >
                        <div
                            style={{
                                textAlign: 'left',
                                cursor: 'pointer',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                padding: '5px 20px'
                            }}
                        >
                            <span style={{ fontSize: 14, marginBottom: 6 }}>Equipments In Use</span>
                            <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>{state.equipment?.equipmentsInUse}</div>
                        </div>
                    </Card>
                </div>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container style={{ padding: 5, boxSize: 'none' }}>
                    <Card className={classes.root}>
                        <Grid container xs={12}>
                            <Grid item xs={8}>
                                <Typography vraient="h5" color="secondary" className="dashHeading">
                                    Equipments In Use
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ float: 'right', marginRight: 40 }}>
                                    <select onChange={handleBranchChange} className="selectclass" value={state.selectedBranch}>
                                        <option value={'Default'}>All Branches</option>
                                        {state.branches?.map((btn, i) => {
                                            return (
                                                <option key={i} value={btn.branchShortName}>
                                                    {btn.branchShortName}{' '}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ padding: '5px 5px ' }}>
                            <div
                                style={{
                                    textAlign: 'left',
                                    padding: '0px 20px',
                                    fontFamily: ` Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                                    fontWeight: 500
                                }}
                            ></div>
                        </Grid>
                        <div style={{ padding: '0px 30px' }}>
                            <hr
                                style={{
                                    flexShrink: 0,
                                    borderWidth: '0px 0px thin',
                                    borderStyle: 'solid',
                                    borderColor: 'rgba(58, 53, 65, 0.12)',
                                    margin: '1.1rem 0px'
                                }}
                            />
                        </div>

                        <Grid container spacing={1} justifyContent="flex-start" style={{ padding: '0px 6px' }}>
                            {state.equipmentInUse?.map((eq) => {
                                if (state.selectedBranch == 'Default') {
                                    return (
                                        <div>
                                            <Grid
                                                item
                                                style={{ padding: '5px 5px', width: '150px' }}
                                                //
                                                onClick={() => setWindow(true, null, eq.specialEquipmentID, eq.specialEquipmentCode)}
                                            >
                                                <div
                                                    style={{ borderLeft: '10px solid #88EBA4', padding: '5px 10px 0px 10px', width: '100%', cursor: 'pointer' }}
                                                >
                                                    <div style={{ paddingBottom: 10, textAlign: 'left' }}>
                                                        <span style={{ fontSize: 14, marginBottom: 6, fontFamily: `"Public Sans", sans-serif` }}>
                                                            {eq.specialEquipmentCode}
                                                        </span>
                                                        <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>
                                                            {' '}
                                                            {eq.total}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </div>
                                    );
                                } else if (eq.branch == state.selectedBranch) {
                                    return (
                                        <div>
                                            <Grid
                                                item
                                                style={{ padding: '5px 5px', width: '150px' }}
                                                //change
                                                onClick={() => setWindow(true, null, eq.specialEquipmentID, eq.specialEquipmentCode)}
                                            >
                                                <div
                                                    style={{ borderLeft: '10px solid #88EBA4', padding: '5px 10px 0px 10px', width: '100%', cursor: 'pointer' }}
                                                >
                                                    <div style={{ paddingBottom: 10, textAlign: 'left' }}>
                                                        <span style={{ fontSize: 14, marginBottom: 6, fontFamily: `"Public Sans", sans-serif` }}>
                                                            {eq.specialEquipmentCode}
                                                        </span>
                                                        <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>
                                                            {' '}
                                                            {eq.total}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </div>
                                    );
                                }
                            })}
                        </Grid>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container style={{ padding: 5, boxSize: 'none' }}>
                    <Card className={classes.root}>
                        <Grid container xs={12}>
                            <Grid item xs={8}>
                                <Typography vraient="h5" color="secondary" className="dashHeading">
                                    Active Equipments
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ float: 'right', marginRight: 40 }}></div>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ padding: '5px 5px ' }}>
                            <div
                                style={{
                                    textAlign: 'left',
                                    padding: '0px 20px',
                                    fontFamily: ` Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                                    fontWeight: 500
                                }}
                            ></div>
                        </Grid>
                        <div style={{ padding: '0px 30px' }}>
                            <hr
                                style={{
                                    flexShrink: 0,
                                    borderWidth: '0px 0px thin',
                                    borderStyle: 'solid',
                                    borderColor: 'rgba(58, 53, 65, 0.12)',
                                    margin: '1.1rem 0px'
                                }}
                            />
                        </div>

                        <Grid container spacing={1} justifyContent="flex-start" style={{ padding: '0px 6px' }}>
                            {state.equipmentByBranch?.map((eq) => {
                                return (
                                    <div>
                                        <Grid
                                            item
                                            style={{ padding: '5px 5px', width: '150px' }}
                                            onClick={() => setWindow('equipment', eq.branchID, null, null)}
                                        >
                                            <div style={{ borderLeft: '10px solid #88EBA4', padding: '5px 10px 0px 10px', width: '100%', cursor: 'pointer' }}>
                                                <div style={{ paddingBottom: 10, textAlign: 'left' }}>
                                                    <span style={{ fontSize: 14, marginBottom: 6, fontFamily: `"Public Sans", sans-serif` }}>{eq.branch}</span>
                                                    <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}> {eq.total}</div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                );
                            })}
                        </Grid>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container style={{ padding: 5, boxSize: 'none' }}>
                    <Card className={classes.root}>
                        <Grid container xs={12}>
                            <Grid item xs={8}>
                                <Typography vraient="h5" color="secondary" className="dashHeading">
                                    Total Equipments Usage
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ float: 'right', marginRight: 40 }}></div>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ padding: '5px 5px ' }}>
                            <div
                                style={{
                                    textAlign: 'left',
                                    padding: '0px 20px',
                                    fontFamily: ` Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                                    fontWeight: 500
                                }}
                            ></div>
                        </Grid>
                        <div style={{ padding: '0px 30px' }}>
                            <hr
                                style={{
                                    flexShrink: 0,
                                    borderWidth: '0px 0px thin',
                                    borderStyle: 'solid',
                                    borderColor: 'rgba(58, 53, 65, 0.12)',
                                    margin: '1.1rem 0px'
                                }}
                            />
                        </div>

                        <Grid container spacing={1} justifyContent="flex-start" style={{ padding: '0px 6px' }}>
                            {state.equipmentTotalUse?.map((eq) => {
                                return (
                                    <div>
                                        {console.log(eq)}
                                        <Grid
                                            item
                                            style={{ margin: '5px 5px', width: '150px' }}
                                            //change
                                            onClick={() => setWindow(false, null, eq.specialEquipmentID, eq.specialEquipmentCode)}
                                        >
                                            <div style={{ borderLeft: '10px solid #88EBA4', padding: '5px 10px 0px 10px', width: '100%', cursor: 'pointer' }}>
                                                <div style={{ paddingBottom: 10, textAlign: 'left' }}>
                                                    <span
                                                        style={{ fontSize: 14, marginBottom: 6, fontFamily: `"Public Sans", sans-serif`, fontWeight: 'bold' }}
                                                    >
                                                        {eq.specialEquipmentCode}
                                                    </span>
                                                    <div>{eq.specialEquipmentDescription}</div>
                                                    <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}> {eq.total}</div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                );
                            })}
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default SpecialEquipmentDash;
