import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import ReportingDashboard from './reportingDashboard';
import AfterSaleReportingDashboard from './afterSaleDashboard';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        // background:'red',
        height: 'calc(100vh - 115px)'
    }
}));

const RepotingTab = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState('2');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <TabContext value={value}>
                <TabList onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="After Sales" value="2" />
                    <Tab label="Sales" value="1" />
                </TabList>

                <TabPanel value="2" style={{ padding: 0 }}>
                    <AfterSaleReportingDashboard />
                </TabPanel>
                <TabPanel value="1" style={{ padding: 0 }}>
                    <ReportingDashboard />
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default RepotingTab;
