import React, { useEffect, useMemo, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import ReactDOM from 'react-dom';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import { AppStorage } from '../../Service/storage-service';
import { ExpBaseURL } from '../../../Core/Service/http-calls';
import { GetExcelFileFromEndpoint } from '../../Service/reportService';
import AppContext from '../../../App/AppContext';

var grid1;
var $ = window.$;

const NestedGijgoGrid = (props) => {
    let isAction = props.columns.find((c) => c.key === 'action');

    const useStyles = makeStyles((theme) => ({
        gijgoGrid: {
            '& .gj-grid-md th:first-of-type': {
                padding: (props) => {
                    return props.isAction ? '12px 5px 12px 5px' : '12px 15px 12px 10px';
                }
            }
        }
    }));

    const classes = useStyles({ isAction: isAction });
    let token = AppStorage.getToken();
    const [state, setState] = useState({
        recordId: props.recordId,
        fileCount: `${props.fileCount || 0}`,
        configName: props.fileUploaderConfigName,
        columns: []
    });
    const [gridReload, setGridReload] = useState(new Date());

    const { hideModal, showToast } = useContext(AppContext);

    const gridFileCountRefresh = () => {
        setGridReload((st) => ({ ...st, gridReload: new Date() }));
    };

    useEffect(async () => {
        let dropDownColNames = [];
        let dropDownList = [];
        let finalColumns = [...props.columns].map((c) => {
            if (c.isDropDown) {
                dropDownColNames.push(c);
            }
            return { ...c };
        });
        if (dropDownColNames.length > 0) {
            dropDownList = typeof props.dropDownListProvider === 'function' ? await props.dropDownListProvider() : props.dropDownListProvider;
        }
        setState((st) => ({ ...st, columns: finalColumns, dropDownList: dropDownList, dropDownColNames: dropDownColNames }));
    }, [props.columns, gridReload]);

    useEffect(async () => {
        if (state.columns.length > 0) {
            pullArchivedJobsAndUpdateState();
        }
    }, [state.columns, props.isReload, props.getUrl]);

    var GijgoFilterExtension = (function () {
        function DropdownFilter(grid, inputProperty, dropdown, changeValueConversion) {
            var id = $(grid).attr('id');
            var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
            var filterRow = $(rowXPath);
            if ($(filterRow).length == 1) {
                var colXPath = 'th [data-field="' + inputProperty.field + '"]';
                var filterInput = $(filterRow).find(colXPath);
                if ($(filterInput).length) {
                    $(filterInput).parent().addClass('grid-filter-select-th');
                    $(dropdown).attr('data-field', inputProperty.field);
                    $(filterInput).replaceWith(dropdown);
                    $(dropdown).on('change', function () {
                        ChangeFilter(grid, inputProperty, $(this), changeValueConversion);
                    });
                }
            }
        }

        function ChangeFilter(grid, inputProperty, ddl, changeValueConversion) {
            if (changeValueConversion) {
                var propArr = changeValueConversion(ddl, inputProperty.field, grid);
                $(propArr).each(function (i, obj) {
                    grid.data().params[obj.inputProperty.field] = obj.value;
                });
                if (propArr.length) {
                    grid.reload();
                }
            } else {
                grid.data().params[`${inputProperty.queryParamName || inputProperty.field}`] = $(ddl).val();
                grid.reload();
            }
        }

        return {
            Dropdown: DropdownFilter
        };
    })();

    const pullArchivedJobsAndUpdateState = async () => {
        window.$(`#${'key1'}`).grid('destroy', true, true);

        const onSuccessFunc = function (response) {
            grid1.render(props.dataConverter(response));
        };

        grid1 = window.$(`#${'key1'}`).grid({
            dataSource: {
                url: `${ExpBaseURL}/api/${props.getUrl}`,
                headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                success: onSuccessFunc
            },
            ...(props.minWidth !== null && { minWidth: 1200 }),
            headerFilter: {
                type: 'onchange'
            },

            columns: [...state.columns],
            detailTemplate: props.detailTemplate(),
            pager: { limit: 20, sizes: [20, 50, 100, 500, 1000, 2000, 5000] }
        });

        if (state.dropDownColNames.length > 0) {
            state.dropDownColNames.forEach((q) => {
                var ddl = $('<select class="grid-filter-select"><option value="" >All</option></select>');
                state.dropDownList[`${q.listIdFromAPIResponse}`].forEach((k) => {
                    let value = k[q.dropDownValueField || 'id'];
                    if (value === undefined || value === null) {
                        console.error(`${q.dropDownValueField} Invalid Property`);
                    }
                    ddl.append(`<option value="${value}" >${k[q.dropDownLableField || '']}</option>`);
                });
                GijgoFilterExtension.Dropdown(grid1, q, ddl);
            });
        }

        grid1.reload();
    };

    const GijgoActionBtn = (grid, propertyName, dropdown) => {
        var id = $(grid).attr('id');
        var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
        var filterRow = $(rowXPath);
        if ($(filterRow).length == 1) {
            var colXPath = 'th [data-field="' + `action` + '"]';
            var filterInput = $(filterRow).find(colXPath);
            if ($(filterInput).length) {
                $(filterInput).replaceWith(dropdown);
            }
        }
    };
    const downloadCSVWrapper = async () => {
        showToast('Collating Data... This may take a while.');

        const data = grid1.data().params;

        let downloadURL = `${props.getUrl}${props.getUrl.includes('?') ? '&' : '?'}fileType=excel`;

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                downloadURL += `&${key}=${value}`;
            }
        }

        await GetExcelFileFromEndpoint(downloadURL, props.downloadName);
    };

    return (
        <Grid container>
            <Grid container className="report-screen-gijgo " item xs={12} sm={12} style={{ margin: '10px auto', overflow: 'auto', position: 'relative' }}>
                {props.downloadName && (
                    <Grid item style={{ bottom: '3px', left: '16px', zIndex: 99, position: 'absolute' }}>
                        <Tooltip title="Download Excel" arrow>
                            <IconButton aria-label="delete" style={{ padding: '0px' }}>
                                <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} style={{ color: 'red' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}

                <table id={`${'key1'}`}></table>
            </Grid>
        </Grid>
    );
};

export default NestedGijgoGrid;
