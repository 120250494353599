import React, { useEffect, useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import AppContext from '../../../App/AppContext';
import { Link } from 'react-router-dom';
import { getauditorDashboard } from '../../../Core/Service/Auditor-services';
import './dashboard.scss';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 3,
        border: '2px solid #f5f5f5 ',
        Opacity: 0.9,
        minWidth: 120,
        padding: '5px 0px',
        background: '#f5f5f5',
        borderRadius: 10
    },
    lable: {
        fontFamily: `"Public Sans", sans-serif`,
        textDecoration: 'none',
        color: 'black'
    }
}));
const AuditorDashboard = (props) => {
    const [state, setState] = useState({
        rows_new: []
    });
    const { showToast } = useContext(AppContext);
    const classes = useStyles();

    useEffect(async () => {
        let res = await getauditorDashboard();
        if (res.success) {
            setState((st) => ({ ...st, rows_new: res.data }));
        }
        if (!res.success) {
            showToast(res.message);
        }
    }, []);
    const cards = [
        {
            id: 1,
            name: 'Current Month',
            color: '#3498db',
            count: state.rows_new.currentMonth
        },
        {
            id: 2,
            name: 'Current Year',
            color: '#2ecc71',
            count: state.rows_new.currentYear
        },
        {
            id: 3,
            name: 'Last Year',
            color: '#dc3545',
            count: state.rows_new.preYear
        },
        {
            id: 4,
            name: 'Total',
            color: '#868e96',
            count: state.rows_new.total
        }
    ];

    return (
        <div className="auditor-dashboard">
            <Container className="cardGrid" maxWidth="lg">
                <Grid item container xs={12} sm={12} md={12} style={{ padding: 5, boxSize: 'none' }}>
                    <Card className={classes.root}>
                        <Grid container xs={12}>
                            <Grid item style={{ padding: '0px 30px' }}>
                                <Typography vraient="h5" color="secondary" className="dashHeading">
                                    Available Jobs
                                </Typography>
                            </Grid>
                        </Grid>
                        <div style={{ padding: '0px 30px' }}>
                            <hr
                                style={{
                                    flexShrink: 0,
                                    borderWidth: '0px 0px thin',
                                    borderStyle: 'solid',
                                    borderColor: 'rgba(58, 53, 65, 0.12)',
                                    margin: '1.1rem 0px'
                                }}
                            />
                        </div>
                        <Grid container spacing={1} justifyContent="flex-start" style={{ padding: '0px 30px' }}>
                            {cards.map((option) => (
                                <Grid item xs={12} sm={6} lg={3}  style={{ padding: '10px 10px 10px 0px' }}>
                                    <div
                                        style={{
                                            borderLeft: `10px solid ${option.color}`,
                                            padding: '5px 10px 0px 10px',
                                            width: '100%',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/Auditor/${option.name}/${option.id}`}>
                                            <div className={classes.lable} style={{ paddingBottom: 10, textAlign: 'left' }}>
                                                <span style={{ fontSize: 18, marginBottom: 10 }}> {option.name} </span>
                                                <div style={{ fontSize: 30, fontWeight: 600 }}> {option.count} </div>
                                            </div>
                                        </Link>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Card>
                </Grid>
            </Container>
        </div>
    );
};
export default AuditorDashboard;
