import React from 'react';
import { moment } from 'moment';
import { Grid, FormLabel } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import { AppStorage } from '../../../../../Core/Service/storage-service';

const baseColumns = [
    { field: 'rn', title: `Reg No`, width: 180 },
    {
        field: 'mc',
        title: `Model`,
        width: 120,
        showColumnTotal: true
    },
    {
        field: 'ex',
        sortable: true,
        title: `Sales Exec`,
        width: 120,
        showColumnTotal: true
    },
    {
        field: 'bal',
        sortable: true,
        title: `Balance`,
        width: 120,
        showColumnTotal: true,
        align: 'right'
    },
    { field: 'id', sortable: true, title: `Invoice Date`, width: 120, showColumnTotal: true, type: 'date', format: 'dd-mmm-yyyy' },
    { field: 'age', sortable: true, title: `Age`, width: 120, showColumnTotal: true, align: 'right' },
    {
        field: 'dn',
        sortable: true,
        title: `Invoice No`,
        width: 150,
        showColumnTotal: true
    },
    { field: 'loc', sortable: true, title: `Sold By`, width: 120, showColumnTotal: true },
    { field: 'ac', sortable: true, title: `Account`, width: 120, showColumnTotal: true },
    {
        field: 'cn',
        sortable: true,
        title: 'Customer Name',
        width: 120,
        showColumnTotal: true
    }
];
const crumbs = (measuredesc) => [
    { name: 'Home', path: '/' },
    { name: measuredesc, active: true }
];

const getCrumbs = (menuPermissionCounter,ParamMeasureID, ParamMeasuredesc, ParamMc, ParamMonth) => {
    let temp = {};
    let final = [{ name: 'Home', path: '' }  ];
    if (menuPermissionCounter !== 1) {
        final.push({ name: 'Reports', path: '/ReportsDashboard' });
    }
    final.push({ name: 'Aged Measures', path: '/AgedMeasures'})
    
    if (ParamMeasureID == '11') {
        temp = { name: `${ParamMeasuredesc}`, path: '/AgedMeasures/NewFusoStock' };
    } else if (ParamMeasureID == '18') {
        temp = { name: `${ParamMeasuredesc}`, path: '/AgedMeasures/NewMaxusVans' };
    } else if (ParamMeasureID == '10') {
        temp = { name: `${ParamMeasuredesc}`, path: '/AgedMeasures/NewTruckStock' };
    } else if (ParamMeasureID == '12') {
        temp = { name: `${ParamMeasuredesc}`, path: '/AgedMeasures/NewVanStock' };
    } else if (ParamMeasureID == '14') {
        temp = { name: `${ParamMeasuredesc}`, path: '/AgedMeasures/UsedFusoStock' };
    } else if (ParamMeasureID == '19') {
        temp = { name: `${ParamMeasuredesc}`, path: '/AgedMeasures/UsedMaxusVans' };
    } else if (ParamMeasureID == '16') {
        temp = { name: `${ParamMeasuredesc}`, path: '/AgedMeasures/UsedNon-franchiseStock' };
    } else if (ParamMeasureID == '13') {
        temp = { name: `${ParamMeasuredesc}`, path: '/AgedMeasures/UsedTruckStock' };
    } else if (ParamMeasureID == '15') {
        temp = { name: `${ParamMeasuredesc}`, path: '/AgedMeasures/UsedVanStock' };
    } else {
        temp = { name: `${ParamMeasuredesc}`, active: true };
    }
    final = [...final, { ...temp }];
    if (ParamMc || ParamMonth) {
        temp = {
            name: `${ParamMc ? ParamMc : ''} ${ParamMonth > 0 ? `(${ParamMonth} ${ParamMonth == 1 ? 'Month' : 'Months'})` : ''}`,
            active: true
        };
        final = [...final, { ...temp }];
    }

    return final;
};

const GetUsedAndNewVehicleDebt = (props) => {
    let menuPermissionCounter = AppStorage.menuPermissionCounter();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamMeasureID = params.get('MeasureID') || '';
    let ParamMeasuredesc = params.get('measuredesc') || '';
    let ParamMonth = params.get('month') || '';
    let ParamFranchise = params.get('franchise') || '';
    let ParamMc = params.get('mc') || '';

    let baseUrl = '';

    if (ParamMeasureID == 11 || ParamMeasureID == 18 || ParamMeasureID == 10 || ParamMeasureID == 12) {
        baseUrl = `sales/GetNewVehicleDebt?Franchise=${ParamFranchise}&Month=${ParamMonth}&mc=${ParamMc}`;
    } else {
        baseUrl = `sales/GetUsedVehicleDebt?Franchise=${ParamFranchise}&Month=${ParamMonth}&mc=${ParamMc}`;
    }

    const detailTemplate = () => {
        return `<div style='width:100%'>
                <table style='width:100%;'>
                    <tr style='background-color:#DCDCDC;' >
                        <th style='font-weight:750; width:30%; text-align:left;'>Invoice Name</th >
                        <th style='font-weight:750; text-align:left;'>Stockbook Number</th>
                        <th style='font-weight:750; text-align:left;'>Order Date</th>
                        <th style='text-align:left;font-weight:750;'>Reg Date</th>
                    </tr>
                    <tr>
                        <td>{iname}</td>
                        <td>{sn}</td>
                        <td>{od}</td>
                        <td>{rd}</td>
                    </tr>
                </table>
            </div>`;
    };
    // Invoice Name	Comm Number	Stockbook Number	Order Date	Consignment Date	Build Date	Adoption Date	Reg Date
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={getCrumbs(menuPermissionCounter,ParamMeasureID, ParamMeasuredesc, ParamMc, ParamMonth)} />
            <Grid item container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={baseColumns}
                        getUrl={baseUrl}
                        detailTemplate={detailTemplate}
                        // isShowTotal={true}
                        // hidePagination={true}
                        // hideFilterRow={true}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default GetUsedAndNewVehicleDebt;
