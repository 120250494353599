import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { Grid, FormLabel, Tooltip, IconButton } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { TextBox } from '../../../../../Core/FormInput';
import { ExpBaseURL } from '../../../../../Core/Service/http-calls';
import { AppStorage } from '../../../../../Core/Service/storage-service';
import formatters from '../../../../../Core/Grid/inputFormatter';
import DialogComp from '../../../../../Core/Modal/dialogModal';
import ViewPdfModal from '../../../../../Core/Modal/PdfViweModal';
import { useMemo } from 'react';
import { getAgedMeasuresViewDocument } from '../../../../../Core/Service/ReportScreens-service';
import ReactDOM from 'react-dom';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { GetExcelFileFromEndpoint } from '../../../../../Core/Service/reportService';
import AppContext from '../../../../../App/AppContext';

const baseNominalColumns = [
    {
        title: 'Posted',
        field: 'pd',
        width: 100,
        sortable: true,
        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'Document Date',
        field: 'dd',
        width: 100,
        sortable: true,
        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    { title: 'Base Value', field: 'bv', width: 90, sortable: true, renderer: formatters.CurrencyThousandSeparator, align: 'right' },
    { title: 'Document Number', field: 'dn', width: 100, sortable: true, align: 'right' },
    { title: 'Journal Reference', field: 'jr', width: 110, sortable: true }
];

const baseColumns = [
    { field: 'bn', title: 'Branch', width: 120, showColumnTotal: false },
    { field: 'cc', title: 'Cost Centre', width: 120, showColumnTotal: false },
    { field: 'ec', title: 'Expense Code', width: 120, showColumnTotal: false },
    { field: 'currentPostings', title: 'Current Postings', width: 120, showColumnTotal: true, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'broughtForward', title: 'Brought Forward', width: 120, showColumnTotal: true, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    {
        field: 'dateLastPosting',
        title: 'Last Posted',
        width: 120,
        showColumnTotal: false,
        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    }
];
const screenName = {
    C: 'Consumables',
    M: 'Mobilovan',
    R: 'NPW Rectification',
    P: 'Parts Warranty Write Off',
    S: 'Service Warranty Write Off'
};
// const crumbs = (code) => {
//     let final = [
//         { name: 'Home', path: '/' },
//         { name: 'Services', active: true },
//         { name: screenName[code], active: true }
//     ];
//     return final;
// };
const crumbs = (code, menuPermissionCounter) => {
    let list = [{ name: 'Home', path: '/' }];
    if (menuPermissionCounter !== 1) {
        list.push({ name: 'Reports', path: '/ReportsDashboard' });
    }
    if (code) {
        list.push( { name: 'Services', active: true }, { name: screenName[code], active: true });
    }
    return list;
};

var $ = window.$;
var mainGrid, opGrid;

const ServiceNominals = (props) => {    
    let menuPermissionCounter = AppStorage.menuPermissionCounter();
    const { showToast } = useContext(AppContext);

    let startDate = moment().startOf('month').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');

    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate
    });
    const fieldChange = (e) => {
        let { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    let token = AppStorage.getToken();
    const onSuccessFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        mainGrid.render(obj);
    };

    const onSuccessOperatorFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        opGrid.render(obj);
    };

    const documentHandler = async (record) => {
        let res = await getAgedMeasuresViewDocument(record.ifn);
        if (res) {
            setState((st) => ({ ...st, showPdfModal: true, pdfFileData: res }));
        } else {
            console.error(res.message);
        }
    };

    const docEditButton = (value, record) => {
        const spn = document.createElement('span');
        let isFile = record.ifn;
        const lnk = (
            <span style={{ cursor: isFile ? 'pointer' : 'text' }} onClick={isFile ? () => documentHandler(record) : ''}>
                {value}
            </span>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const nominalColumns = useMemo(() => {
        let tempColumns = [...baseNominalColumns];
        let ViewDocCol = tempColumns.find((element) => element.field === 'dn');
        if (ViewDocCol) {
            ViewDocCol.renderer = docEditButton;
        }
        return tempColumns;
    }, []);
    let getUrl = `${ExpBaseURL}/api/services/ServiceNominalsList`;
    const PullSalesNominalData = async () => {
        window.$(`#ServiceNominals`).grid('destroy', true, true);
        mainGrid = $('#ServiceNominals').grid({
            primaryKey: 'branchID',
            dataSource: {
                url: getUrl,
                data: { Type: props.type },
                headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                success: onSuccessFunc
            },
            detailTemplate: '<div><table/></div>',
            headerFilter: false,
            columns: baseColumns,
            pager: { limit: 10 }
        });
        mainGrid.on('dataBound', function (e, records, totalRecords) {
            var sumCurrent = 0,
                sumBroughtForward = 0,
                $tfoot;
            $.each(records, function () {
                sumCurrent += parseFloat(this.currentPostings);
                sumBroughtForward += parseFloat(this.broughtForward);
            });
            $tfoot = mainGrid.children('tfoot');
            $tfoot.children('tr[data-role="TotalAmount"]').remove();
            $tfoot.children('tr[data-role="pager"]').remove();
            $tfoot.prepend(
                '<tr data-role="TotalAmount"><th></th><th></th><th></th><th>Total</th><th style="text-align:right; " >' +
                    formatters.CurrencyThousandSeparator(sumCurrent) +
                    '</th><th style="text-align:right; " >' +
                    formatters.CurrencyThousandSeparator(sumBroughtForward) +
                    '</th><th></th></tr>'
            );
        });
        mainGrid.on('detailExpand', function (e, $detailWrapper, branchID) {
            opGrid = $detailWrapper.find('table').grid({
                params: { branchID: branchID },
                headerFilter: true,
                primaryKey: 'dn',
                dataSource: {
                    url: `${ExpBaseURL}/api/services/ServiceNominalsList`,
                    headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                    data: { Type: props.type, StartDate: state.startDate, EndDate: state.endDate },
                    success: onSuccessOperatorFunc
                },
                columns: nominalColumns
            });
        });

        mainGrid.on('detailCollapse', function (e, $detailWrapper, id) {
            $detailWrapper.find('table').grid('destroy', true, true);
        });
        mainGrid.reload();
    };
    const downloadCSVWrapper = async () => {
        showToast('Collating Data... This may take a while.');
        let downloadURL = `services/ServiceNominalsList?Type=${props.type}&fileType=excel`;
        await GetExcelFileFromEndpoint(downloadURL, screenName[props.type]);
    };

    useEffect(() => {
        PullSalesNominalData();
    }, [state.startDate, state.endDate, props.type]);

    return (
        <div>
            <BreadCrumbs crumbs={crumbs(props.type,menuPermissionCounter)} />
            <Grid container className="report-screen-gijgo " item xs={12} sm={12} style={{ margin: '10px auto', overflow: 'auto', position: 'relative' }}>
                <Grid item style={{ bottom: '14px', left: '3.2%', zIndex: 99, position: 'absolute' }}>
                    <Tooltip title="Download Excel" arrow>
                        <IconButton aria-label="delete" style={{ padding: '0px' }}>
                            <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} style={{ color: 'red' }} />
                        </IconButton>
                    </Tooltip>
                </Grid>

                <table id={`ServiceNominals`}></table>
            </Grid>
            {state.showPdfModal ? (
                <DialogComp title="View PDF" maxWidth="md" onClose={() => setState((st) => ({ ...st, showPdfModal: false }))} fullWidth>
                    <ViewPdfModal pdfFileData={state.pdfFileData} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default ServiceNominals;
