import { Dialog, DialogActions, DialogTitle, FormHelperText, FormLabel, Grid, InputLabel, Tooltip } from '@material-ui/core';
import SignaturePad from 'react-signature-canvas';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useRef, useState, useEffect, useContext } from 'react';
import { DefaultButton, PrimaryButton, SecondaryButton, SelectBox } from '../FormInput';
import { AppStorage } from '../Service/storage-service';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Box } from '@material-ui/core';
import AppContext from '../../App/AppContext';
const appbarData = [
    {
        id: 'T',
        name: 'Top'
    },
    {
        id: 'S',
        name: 'Side'
    }
];
export const UserSignatureReminder = (props) => {
    const checkStatus = (value) => {
        if (value === 1) {
            props.closeSign();
            props.onClose();
        } else if (value === 2 || value === 3) {
            props.postData(null, value);
            props.onClose();
        }
    };
    return (
        <>
            <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle>Do you want to set the default signature?</DialogTitle>
                <DialogActions>
                    <Grid container spacing={1} style={{ padding: '15px' }}>
                        <Grid item xs={4}>
                            <PrimaryButton onClick={() => checkStatus(1)} fullWidth>
                                Yes
                            </PrimaryButton>
                        </Grid>
                        <Grid item xs={4}>
                            <SecondaryButton onClick={() => checkStatus(2)} fullWidth>
                                No
                            </SecondaryButton>
                        </Grid>
                        <Grid item xs={4}>
                            <DefaultButton onClick={() => checkStatus(3)} variant="contained" fullWidth style={{ background: '#5a5a5a', color: 'white' }}>
                                Later
                            </DefaultButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

const UserSignature = (props) => {
    const [state, setState] = useState({ userSignature: null, signAvailable: true, appbarLocation: 'T' });
    const signCanvas = useRef();
    const { portalSettings, userRoles } = useContext(AppContext);

    function updateDashboardList(portalSettings) {
        const updatedList = [];
        if (portalSettings.portalSettingeWIPEnabled) {
            updatedList.push({ id: 1, name: 'eWIP' });
        }
        if (portalSettings.portalSettingReportingEnabled) {
            updatedList.push({ id: 2, name: 'Reporting' });
        }
        if (portalSettings.portalSettingInvoiceEnabled) {
            updatedList.push({ id: 3, name: 'Invoices' });
        }
        if (portalSettings.portalSettingCommunicatorEnabled) {
            updatedList.push({ id: 4, name: 'Communicator' });
        }
        setState((st) => ({ ...st, DashboardList: updatedList }));
    }

    const clear = () => {
        setState((st) => ({
            ...st,
            userSignature: null,
            signAvailable: false,
            imgSrc: null
        }));
        if (signCanvas?.current !== undefined) {
            signCanvas?.current?.clear();
        }
    };

    const GetuserSignature = () => {
        setState((st) => {
            const nst = {
                ...st,
                userSignature: signCanvas.current.toDataURL()
            };
            return nst;
        });
    };

    useEffect(() => {
        updateDashboardList(portalSettings);
        let navBarLocationValue = 'T';
        let getLocalAppbar = localStorage.getItem('_AppbarLocation_');
        if (getLocalAppbar && getLocalAppbar != 'undefined' && getLocalAppbar != 'null') {
            navBarLocationValue = getLocalAppbar || 'T';
        }
        setState((st) => ({
            ...st,
            imgSrc: AppStorage.getSignature(),
            signAvailable: AppStorage.getSignature(),
            defaultInternalDashboard: AppStorage.getDefaultInternalDashboard(),
            appbarLocation: navBarLocationValue
        }));
    }, []);

    const handleData = async () => {
        props.postData(state.signAvailable ? state.signAvailable : state.userSignature, 1, state.defaultInternalDashboard, state.appbarLocation);
        (state.userSignature || state.signAvailable) && props.onClose();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        console.log(value, 'value');
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };
    return (
        <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitle>My Settings</DialogTitle>
                </Box>
                <Box>
                    <IconButton onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogActions>
                <Grid container spacing={2}>
                    {userRoles.includes('internal') && (
                        <>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <FormLabel style={{ marginBottom: '5px' }}>Home Dashboard</FormLabel>
                                <SelectBox
                                    onChange={fieldChange}
                                    value={state.defaultInternalDashboard}
                                    name="defaultInternalDashboard"
                                    List={state.DashboardList || []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <FormLabel style={{ marginBottom: '5px' }}>Appbar</FormLabel>
                                <SelectBox
                                    insertEmpty={false}
                                    onChange={fieldChange}
                                    value={state.appbarLocation}
                                    name="appbarLocation"
                                    List={appbarData || []}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <div style={{ position: 'relative' }}>
                            <InputLabel shrink>Signature</InputLabel>
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    marginTop: state.imgSrc ? '-5px' : '-2px'
                                }}
                            >
                                <Tooltip title="Clear Signature">
                                    <DeleteIcon
                                        onClick={clear}
                                        style={{
                                            cursor: 'pointer',
                                            marginTop: -20
                                        }}
                                    />
                                </Tooltip>
                            </div>
                            {state.signAvailable ? (
                                <img src={state.imgSrc} height={143} alt="Signature"></img>
                            ) : (
                                <div className="userSign">
                                    <SignaturePad
                                        ref={signCanvas}
                                        canvasProps={{
                                            className: 'signatureCanvas'
                                        }}
                                        onEnd={() => {
                                            GetuserSignature();
                                        }}
                                        clearOnResize={false}
                                    />
                                </div>
                            )}
                        </div>
                    </Grid>
                    {!state.userSignature && !state.imgSrc && (
                        <Grid item xs={12}>
                            <FormHelperText error>Please draw your signature inside the box</FormHelperText>
                        </Grid>
                    )}
                    <Grid xs={6} item>
                        <SecondaryButton fullWidth onClick={handleData}>
                            SAVE
                        </SecondaryButton>
                    </Grid>
                    <Grid xs={6} item>
                        <SecondaryButton fullWidth onClick={props.onClose}>
                            CANCEL
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default UserSignature;
