import { Grid, FormLabel, FormControlLabel, InputLabel } from '@material-ui/core';
import React from 'react';
import TabView from '../../../Core/Controls/Tabs';
import { SecondaryCheckbox, Multiselect, SelectBox } from '../../../Core/FormInput';
import EwipModules from './ewipModule';

export default function UserModules(props) {
    const { state, handleCheckbox, AutoFranchiseChange, selectedData, franchiseData, portalSettings } = props;
    const tabs = [
        ...(portalSettings.portalSettingeWIPEnabled
            ? [
                  {
                      label: 'eWIP',
                      body: (
                          <div>
                              <EwipModules
                                  handleClick={props.handleClick}
                                  brancheData={props.brancheData}
                                  AutoBrChange={props.AutoBrChange}
                                  masterData={props.masterData}
                                  AutoWorkChange={props.AutoWorkChange}
                                  state={state}
                                  handleCheckbox={handleCheckbox}
                                  selectedData={selectedData}
                              />
                          </div>
                      )
                  }
              ]
            : []),
        ...(portalSettings.portalSettingReportingEnabled && state.userType == 'internal'
            ? [
                  {
                      label: 'Reporting',
                      body: (
                          <Grid container spacing={1}>
                              <Grid item xs={12}>
                                  <FormLabel component="legend" className="btn_add">
                                      Reporting Access
                                  </FormLabel>
                              </Grid>
                              <Grid item>
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.eReportingAllowed} onChange={handleCheckbox} name="eReportingAllowed" />}
                                      label="Reporting Allowed?"
                                  />
                              </Grid>
                              <Grid item>
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.canAccessBudget} onChange={handleCheckbox} name="canAccessBudget" />}
                                      label="Budget Allowed?"
                                  />
                              </Grid>
                              <Grid item>
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.isAgedMeasure} onChange={handleCheckbox} name="isAgedMeasure" />}
                                      label="Aged Measure?"
                                  />
                                  <FormControlLabel control={<SecondaryCheckbox checked={state.isDOC} onChange={handleCheckbox} name="isDOC" />} label="DOC" />
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.isKPIs} onChange={handleCheckbox} name="isKPIs" />}
                                      label="KPIs"
                                  />
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.isParts} onChange={handleCheckbox} name="isParts" />}
                                      label="Parts"
                                  />
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.isSales} onChange={handleCheckbox} name="isSales" />}
                                      label="Sales"
                                  />
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.isService} onChange={handleCheckbox} name="isService" />}
                                      label="Service"
                                  />
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.isWorkShop} onChange={handleCheckbox} name="isWorkShop" />}
                                      label="Workshop"
                                  />
                              </Grid>
                          </Grid>
                      )
                  }
              ]
            : []),
        ...(portalSettings.portalSettingCommunicatorEnabled
            ? [
                  {
                      label: 'Communicator ',
                      body: (
                          <Grid container spacing={1}>
                              <Grid item xs={12}>
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.eCommunicatorAllowed} onChange={handleCheckbox} name="eCommunicatorAllowed" />}
                                      label="Communicator Allowed?"
                                  />
                              </Grid>
                              {state.userType === 'customer' && (
                                  <Grid item xs={12}>
                                      <InputLabel style={{ marginBottom: 10 }} shrink>
                                          Customer Groups
                                      </InputLabel>
                                      <SelectBox
                                          List={props.custmerGroup}
                                          name="userCustomerGroupID"
                                          value={state.userCustomerGroupID}
                                          onChange={props.fieldChange}
                                      />
                                  </Grid>
                              )}
                          </Grid>
                      )
                  }
              ]
            : []),
        ...(portalSettings.portalSettingInvoiceEnabled
            ? [
                  {
                      label: 'Invoice ',
                      body: (
                          <Grid container spacing={1}>
                              <Grid item xs={12}>
                                  <FormControlLabel
                                      control={<SecondaryCheckbox checked={state.eInvoiceAllowed} onChange={handleCheckbox} name="eInvoiceAllowed" />}
                                      label="Invoice Allowed?"
                                  />
                              </Grid>
                          </Grid>
                      )
                  }
              ]
            : [])
    ];

    return (
        <div className="screen">
            <TabView tabList={tabs} id="testing_tabs" tabLableSize="1.0rem" tabLableWeight="600" />
        </div>
    );
}
