import React, { useMemo } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from 'react';
import DialogComp from '../../../../Core/Modal/dialogModal';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import ReportGijgoGrid from '../../../internal/ReportsScreens/GijgoForReportsScreen';
import { SquareButton } from '../../../../Core/FormInput/AppButton';
import AddEditDynamicFormFields from './AddEditDynamicFields';
import { ArrowDownwardRounded, ArrowUpwardRounded, SignalCellularNoSimOutlined } from '@material-ui/icons';
import { swapDynamicFormFields } from '../../../../Core/Service/dynamic-forms-service';

const baseColumns = [
    { field: 'action', sortable: false, title: ``, width: 90, filterable: false },
    { field: 'sequence', sortable: false, title: `Index`, hidden: true },
    { field: 'label', sortable: false, title: `Label` },
    { field: 'dynamicFormListName', sortable: false, title: `List Name`, hidden: true },
    { field: 'ocrDocument', sortable: false, title: `Document Name` },
    { field: 'isRequired', sortable: false, title: `Required` },
    { field: 'showView', sortable: false, title: `Can View` },
    { field: 'showUpload', sortable: false, title: `Can Upload` },
    { field: 'showEmail', sortable: false, title: `Can Email` }
];

const crumbs = (path) => [
    { name: 'Home', path: '' },
    { name: 'Dynamic Forms', path: '/DynamicFormsList' },
    { name: 'Dynamic Forms Field', active: true }
];

const DynamicFormFields = (props) => {
    const [state, setState] = useState({
        showModal: false,
        smallSequence: null,
        bigSequence: null,
    });

    const handleClose = (res) => {
        let newSt = {};
        newSt.showModal = false;
        newSt.deleteModal = false;
        if (res) {
            newSt.isReload = new Date();
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };

    const handleEdit = (val) => {
        setState((st) => ({
            ...st,
            showModal: true,
            dynamicFormFieldId: val?.id
        }));
    };

    const handleData = (res) => {
        const data = res.data.list.sort((a, b) => { return a.sequence - b.sequence; })

        setState((st) => ({
            ...st,
            smallSequence: data.reduce((acc, loc) => acc.sequence < loc.sequence ? acc : loc).sequence,
            bigSequence: data.reduce((acc, loc) => acc.sequence > loc.sequence ? acc : loc).sequence,
            data: data,
        }));

        if(!state.data || state.data == null) {
            setState((st) => ({
                ...st,
                isReload: new Date()
            }));
        }

        return {
            records: data,
            total: res.data.total
        }
    }

    const handleMoveClick = async (record, move) => {
        let switchRecord;
        let index = state?.data?.map(function (e) { return e.id; }).indexOf(record.id);

        if (move == "up") {
            switchRecord = state.data[index - 1]
        }

        if (move == "down") {
            switchRecord = state.data[index + 1]
        }

        const formID = PramDynamicFormID
        const rec1 = record.id
        const rec2 = switchRecord.id;

        const res = await swapDynamicFormFields(formID, rec1, rec2).then(() => {
            setState((st) => ({
                ...st,
                data: null,
                isReload: new Date()
            }));
        });
    }

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <>
                <Grid container>
                    <Grid item xs={6}>
                        {record.sequence != state.smallSequence && (
                            <Grid item xs={12} style={{ marginBottom: '5px' }}>
                                <SquareButton style={{ backgroundColor: '#183B68', borderRadius: '20%', width: '25px', height: '25px', textAlign: 'center', padding: '0px 0px 0px 0px' }} onClick={() => handleMoveClick(record, "up")}>
                                    <ArrowUpwardRounded fontSize="small" style={{ color: 'white' }} />
                                </SquareButton>
                            </Grid>
                        )}
                        {record.sequence != state.bigSequence && (
                            <Grid item xs={12}>
                                <SquareButton style={{ backgroundColor: '#183B68', borderRadius: '20%', width: '25px', height: '25px', textAlign: 'center', padding: '0px 0px 0px 0px' }} onClick={() => handleMoveClick(record, "down")}>
                                    <ArrowDownwardRounded fontSize="small" style={{ color: 'white' }} />
                                </SquareButton>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <SquareButton
                            style={{
                                backgroundColor: '#183B68',
                                borderRadius: '20%',
                                width: '25px',
                                height: '25px',
                                textAlign: 'center',
                                padding: '0px 0px 0px 0px'
                            }}
                            onClick={() => handleEdit(record)}
                        >
                            <EditIcon fontSize="small" style={{ color: 'white' }} />
                        </SquareButton>
                    </Grid>
                </Grid>
            </>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');
        if (colAction) {
            colAction.renderer = editButton;
        }
        return col;
    }, [state.isReload]);

    let PramDynamicFormID = props.match.params.DynamicFormID || '';
    const url = `DynamicFormFields?DynamicFormID=${PramDynamicFormID}`;

    return (
        <div className="report-screen-container">
            <Grid container justify="space-between">
                <Grid item>
                    <BreadCrumbs crumbs={crumbs()} />
                </Grid>
                <Grid item style={{ paddingTop: '5px' }}>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '0%',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 10px 0px 10px'
                        }}
                        onClick={handleEdit}
                    >
                        <span style={{ color: 'white', fontSize: 12 }}>+ Add Dynamic Form Field</span>
                    </SquareButton>
                </Grid>
            </Grid>
            <ReportGijgoGrid
                dataConverter={(res) => handleData(res)}
                columns={column}
                getUrl={url}
                isReload={state.isReload}
                isShowTotal={false}
            />
            {state.showModal ? (
                <DialogComp
                    title={`${state.id ? 'Update Dynamic Form Fields' : 'Add Dynamic Form Fields'}`}
                    maxWidth="lg"
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showModal: false
                        }))
                    }
                    fullWidth
                    overflow="auto"
                >
                    <AddEditDynamicFormFields onClose={handleClose} DynamicFormID={PramDynamicFormID} dynamicFormFieldId={state.dynamicFormFieldId} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default DynamicFormFields;
