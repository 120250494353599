import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import formatters from '../../../../Core/Grid/inputFormatter';
import { getReportingInternalHighLevelDashboard } from '../../../../Core/Service/ReportScreens-service';
import './miniStyle.scss';
import { useHistory } from 'react-router-dom';
const MiniViewReportingDashboard = (props) => {
    const [state, setState] = useState({ data: {}, parts: [], isLoader: true });
    const history = useHistory();
    const handleRedirect = () => {
        let link = '/RepotingTab';
        history.push({
            pathname: link
        });
    };

    useEffect(async () => {
        let res = await getReportingInternalHighLevelDashboard();
        setState((st) => ({
            ...st,
            data: res.data.data[0],
            parts: res.data.parts,
            isLoader: false
        }));
    }, []);

    return (
        <Grid
            container
            style={{
                padding: '10px 0px',
                height: 'calc(100vh - 105px)'
            }}
            justifyContent="center"
            alignItems="center"
        >
            {state.isLoader ? (
                <CircularProgress color="secondary" />
            ) : (
                <Grid item xs={10} sm={9} lg={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Grid container className="mainContainer" style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" style={{ borderRadius: '4px' }}>
                                                Sold<span style={{ fontSize: '12px', marginLeft: '5px' }}>(Hours/Value)</span>
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }}>
                                                <span style={{ color: state.data?.soldHoursColour ? state.data?.soldHoursColour : 'black' }}>
                                                    {formatters.ThousandSeparatorWithoutZero(state.data?.soldHours)}
                                                </span>{' '}
                                                /{' '}
                                                <span style={{ color: state.data?.soldAmountColour ? state.data?.soldAmountColour : 'black' }}>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state.data?.soldAmount)}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Grid container className="mainContainer" style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" style={{ borderRadius: '4px' }}>
                                                WIP<span style={{ fontSize: '12px', marginLeft: '5px' }}>(Hours/Value @RR)</span>
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }}>
                                                <span style={{ color: state.data?.wipHoursColour ? state.data?.wipHoursColour : 'black' }}>
                                                    {formatters.ThousandSeparatorWithoutZero(state.data?.wipHours)}
                                                </span>{' '}
                                                /{' '}
                                                <span style={{ color: state.data?.wipAmountColour ? state.data?.wipAmountColour : 'black' }}>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state.data?.wipAmount)}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Grid container className="mainContainer" style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" style={{ borderRadius: '4px' }}>
                                                No. of Technicians
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }}>
                                                <span style={{ color: 'black' }}>{formatters.ThousandSeparatorWithoutZero(state.data?.technicianBudget)}</span>{' '}
                                                /{' '}
                                                <span style={{ color: state.data?.technicianBudget > state.data?.technicianWorking ? 'red' : 'green' }}>
                                                    {formatters.ThousandSeparatorWithoutZero(state.data?.technicianWorking)}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* //// */}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Grid container className="mainContainer" style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" style={{ borderRadius: '4px' }}>
                                                Customer throughput MTD
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                                <span style={{ color: 'black' }}>{formatters.ThousandSeparatorWithoutZero(state.data?.uniqueCustomers)}</span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <Grid
                                        container
                                        justifyContent="space-around"
                                        className="mainContainer"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleRedirect()}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="h6" style={{ borderRadius: '4px', textAlign: 'left', paddingLeft: '5px' }}>
                                                {/* Parts */}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={4} lg={3}>
                                            <Typography
                                                style={{
                                                    fontSize: 20,

                                                    cursor: 'pointer',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                Warranty<span style={{ fontSize: '12px', marginLeft: '5px' }}>(Hours/Value)</span>
                                            </Typography>

                                            <Typography
                                                style={{ fontSize: 14, fontWeight: '600', cursor: 'pointer', textAlign: 'left' }}
                                                // onClick={() => GoToDailyInvoicedHours({ startDateType: 'mtd' })}
                                            >
                                                <span>
                                                    <Typography
                                                        style={{
                                                            fontSize: 25,
                                                            fontWeight: '600',
                                                            cursor: 'pointer',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <span style={{ color: 'black' }}>
                                                            {formatters.ThousandSeparatorWithoutZero(state.data?.warrantyHours)}
                                                        </span>{' '}
                                                        /{' '}
                                                        <span style={{ color: 'black' }}>
                                                            {formatters.CurrencyThousandSeparatorWithoutZero(state.data?.warrantyAmount)}
                                                        </span>
                                                    </Typography>
                                                </span>
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={4} lg={3}>
                                            <Typography
                                                style={{
                                                    fontSize: 20,

                                                    cursor: 'pointer',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                R&M<span style={{ fontSize: '12px', marginLeft: '5px' }}>(Hours/Value)</span>
                                            </Typography>

                                            <Typography style={{ fontSize: 14, fontWeight: '600', cursor: 'pointer', textAlign: 'left' }}>
                                                <span>
                                                    <Typography
                                                        style={{
                                                            fontSize: 25,
                                                            fontWeight: '600',
                                                            cursor: 'pointer',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <span style={{ color: 'black' }}>{formatters.ThousandSeparatorWithoutZero(state.data?.rmHours)}</span> /{' '}
                                                        <span style={{ color: 'black' }}>
                                                            {formatters.CurrencyThousandSeparatorWithoutZero(state.data?.rmAmount)}
                                                        </span>
                                                    </Typography>
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* /// */}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Grid container className="mainContainer" style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" style={{ borderRadius: '4px' }}>
                                                Surcharges<span style={{ fontSize: '12px', marginLeft: '5px' }}>(Quantity/Value)</span>
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }}>
                                                <span style={{ color: 'black' }}>{formatters.ThousandSeparatorWithoutZero(state.data?.surchargeQuantity)}</span>{' '}
                                                /{' '}
                                                <span style={{ color: 'black' }}>
                                                    {formatters.CurrencyThousandSeparatorWithoutZero(state.data?.surchargeAmount)}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <Grid container className="mainContainer" style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" style={{ borderRadius: '4px', textAlign: 'left', paddingLeft: '5px' }}>
                                                Parts
                                            </Typography>
                                        </Grid>
                                        <Grid container justifyContent="space-around">
                                            {state.parts.map((item) => {
                                                return (
                                                    <Grid item xs={12} sm={4} lg={3}>
                                                        <Typography
                                                            style={{
                                                                fontSize: 20,
                                                                fontWeight: '600',
                                                                cursor: 'pointer',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            {item.partsDepartment}
                                                        </Typography>

                                                        <Typography style={{ fontSize: 14, fontWeight: '600', cursor: 'pointer', textAlign: 'left' }}>
                                                            <span style={{ color: item.mtdBudget > item.partsAmount ? 'red' : 'green' }}>
                                                                <Typography
                                                                    style={{
                                                                        fontSize: 20,
                                                                        fontWeight: '600',
                                                                        cursor: 'pointer',
                                                                        textAlign: 'left'
                                                                    }}
                                                                >
                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(item.partsAmount)}
                                                                </Typography>
                                                            </span>
                                                        </Typography>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default MiniViewReportingDashboard;
