import { Grid, TableContainer } from '@material-ui/core';

import React, { useContext, useState } from 'react';
import { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import '../commonStyle.scss';
import AppContext from '../../../../../App/AppContext';
import { addReportingBudget, getReportingBudget } from '../../../../../Core/Service/ReportScreens-service';
import formatters from '../../../../../Core/Grid/inputFormatter';

const useStyles = makeStyles({
    table: {
        minWidth: 1250,
        '& .MuiTableCell-root': {
            padding: '10px'
        }
    },
    tableRowStyle: {
        backgroundColor: '#E0E0E0'
    }
});

const MonthShortNameList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const ReportingBudgetTable = (props) => {
    const [state, setState] = useState({ rows: [], calculatedRows: [], isActive: false, totals: {}, changeRecordIds: [], isChangeTrig: false });
    const { showToast } = useContext(AppContext);
    const classes = useStyles();

    const pullSalesTargetGridData = async () => {
        let res = await getReportingBudget(props?.type?.id, props.yearCode?.id);
        let temp = res.data;
        setState((st) => {
            return { ...st, rows: temp, isChangeTrig: false };
        });
    };

    useEffect(() => {
        props.handleActionTriggerCheck(state.isChangeTrig);
    }, [state.isChangeTrig]);

    useEffect(() => {
        pullSalesTargetGridData();
    }, [props.yearCode?.id]);

    useEffect(() => {
        let tempArr = [...state.rows];
        let rowCount = tempArr.length;
        let colCalculation = {
            reportingBudgetJanTotal: 0,
            reportingBudgetFebTotal: 0,
            reportingBudgetMarTotal: 0,
            reportingBudgetAprTotal: 0,
            reportingBudgetMayTotal: 0,
            reportingBudgetJunTotal: 0,
            reportingBudgetJulTotal: 0,
            reportingBudgetAugTotal: 0,
            reportingBudgetSepTotal: 0,
            reportingBudgetOctTotal: 0,
            reportingBudgetNovTotal: 0,
            reportingBudgetDecTotal: 0,
            reportingBudgetTotalTotal: 0
        };
        tempArr.forEach((w) => {
            let totals = 0;
            MonthShortNameList.map((q) => {
                totals += w[`reportingBudget${q}`];
            });
            if (props.type.isPercentage == 'Y') {
                w.totals = totals / 12;
            } else {
                w.totals = totals;
            }
            colCalculation.reportingBudgetJanTotal += w.reportingBudgetJan;
            colCalculation.reportingBudgetFebTotal += w.reportingBudgetFeb;
            colCalculation.reportingBudgetMarTotal += w.reportingBudgetMar;
            colCalculation.reportingBudgetAprTotal += w.reportingBudgetApr;
            colCalculation.reportingBudgetMayTotal += w.reportingBudgetMay;
            colCalculation.reportingBudgetJunTotal += w.reportingBudgetJun;
            colCalculation.reportingBudgetJulTotal += w.reportingBudgetJul;
            colCalculation.reportingBudgetAugTotal += w.reportingBudgetAug;
            colCalculation.reportingBudgetSepTotal += w.reportingBudgetSep;
            colCalculation.reportingBudgetOctTotal += w.reportingBudgetOct;
            colCalculation.reportingBudgetNovTotal += w.reportingBudgetNov;
            colCalculation.reportingBudgetDecTotal += w.reportingBudgetDec;
            colCalculation.reportingBudgetTotalTotal += w.totals;
        });
        if (props.type.isPercentage == 'Y') {
            colCalculation.reportingBudgetJanTotal = colCalculation.reportingBudgetJanTotal / rowCount;
            colCalculation.reportingBudgetFebTotal = colCalculation.reportingBudgetFebTotal / rowCount;
            colCalculation.reportingBudgetMarTotal = colCalculation.reportingBudgetMarTotal / rowCount;
            colCalculation.reportingBudgetAprTotal = colCalculation.reportingBudgetAprTotal / rowCount;
            colCalculation.reportingBudgetMayTotal = colCalculation.reportingBudgetMayTotal / rowCount;
            colCalculation.reportingBudgetJunTotal = colCalculation.reportingBudgetJunTotal / rowCount;
            colCalculation.reportingBudgetJulTotal = colCalculation.reportingBudgetJulTotal / rowCount;
            colCalculation.reportingBudgetAugTotal = colCalculation.reportingBudgetAugTotal / rowCount;
            colCalculation.reportingBudgetSepTotal = colCalculation.reportingBudgetSepTotal / rowCount;
            colCalculation.reportingBudgetOctTotal = colCalculation.reportingBudgetOctTotal / rowCount;
            colCalculation.reportingBudgetNovTotal = colCalculation.reportingBudgetNovTotal / rowCount;
            colCalculation.reportingBudgetDecTotal = colCalculation.reportingBudgetDecTotal / rowCount;
            colCalculation.reportingBudgetTotalTotal = colCalculation.reportingBudgetTotalTotal / rowCount;
        }
        tempArr.push(colCalculation);
        setState((st) => {
            return { ...st, calculatedRows: tempArr };
        });
    }, [state.rows]);

    const gridInputChangeHandler = (branchID) => (e) => {
        let tempObj = [...state.rows];
        let changeRecordIds = [...state.changeRecordIds];
        let objIndex = tempObj.findIndex((w) => branchID == w.branchID);
        if (objIndex > -1) {
            changeRecordIds.push(branchID);
            tempObj[objIndex][e.target.name] = +e.target.value;
        }
        setState((st) => {
            return {
                ...st,
                rows: tempObj,
                isChangeTrig: true,
                changeRecordIds: [...new Set(changeRecordIds)]
            };
        });
    };

    const InputWrapper = (name, value, branchID) => {
        return <input type="number" name={name} onChange={gridInputChangeHandler(branchID)} value={value} className="pa-4" style={{ textAlign: 'right' }} />;
    };

    const handleSubmit = async () => {
        let filteredPostData = [...state.changeRecordIds].map((q) => {
            let obj = state.rows.find((p) => q == p.branchID);
            return {
                reportingBudgetID: obj.reportingBudgetID,
                reportingBudgetTypeID: props?.type?.id,
                reportingBudgetBranchID: obj.branchID,
                reportingBudgetYear: props.yearCode?.id,
                reportingBudgetJan: obj.reportingBudgetJan || 0,
                reportingBudgetFeb: obj.reportingBudgetFeb || 0,
                reportingBudgetMar: obj.reportingBudgetMar || 0,
                reportingBudgetApr: obj.reportingBudgetApr || 0,
                reportingBudgetMay: obj.reportingBudgetMay || 0,
                reportingBudgetJun: obj.reportingBudgetJun || 0,
                reportingBudgetJul: obj.reportingBudgetJul || 0,
                reportingBudgetAug: obj.reportingBudgetAug || 0,
                reportingBudgetSep: obj.reportingBudgetSep || 0,
                reportingBudgetOct: obj.reportingBudgetOct || 0,
                reportingBudgetNov: obj.reportingBudgetNov || 0,
                reportingBudgetDec: obj.reportingBudgetDec || 0
            };
        });

        let res = await addReportingBudget(filteredPostData);
        if (res.success) {
            showToast(res.message);
            await pullSalesTargetGridData();
        }
    };

    useEffect(() => {
        props.childFunc.current = handleSubmit;
    }, [state.rows]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {/* <Grid container spacing={2} alignItems="center" justify="flex-end">
                    <Grid item xs={12} sm={4} md={2}>
                        <SecondaryButton className="setFullWidth" onClick={handleSubmit} disabled={!state.isChangeTrig}>
                            Save
                        </SecondaryButton>
                    </Grid>
                </Grid> */}
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 265px)' }} className="input-Number-remove-spinners">
                            <Table className={classes.table} stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={170}></TableCell>
                                        {MonthShortNameList.map((p) => {
                                            return <TableCell align="right">{p}</TableCell>;
                                        })}
                                        <TableCell width={100} align="right">
                                            Total
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.calculatedRows.map((q) => {
                                        if (!q.branchID) {
                                            return (
                                                <TableRow stickyHeader>
                                                    <TableCell width={170}>Group Total</TableCell>
                                                    {MonthShortNameList.map((p) => {
                                                        return (
                                                            <TableCell align="right">{formatters.ToFixedSeparator(q[`reportingBudget${p}Total`])}</TableCell>
                                                        );
                                                    })}
                                                    <TableCell width={100} align="right">
                                                        {formatters.ToFixedSeparator(q.reportingBudgetTotalTotal)}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        } else {
                                            return (
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        {q.branchShortName}
                                                    </TableCell>
                                                    <TableCell>{InputWrapper('reportingBudgetJan', q.reportingBudgetJan, q.branchID)}</TableCell>
                                                    <TableCell>{InputWrapper('reportingBudgetFeb', q.reportingBudgetFeb, q.branchID)}</TableCell>
                                                    <TableCell>{InputWrapper('reportingBudgetMar', q.reportingBudgetMar, q.branchID)}</TableCell>
                                                    <TableCell>{InputWrapper('reportingBudgetApr', q.reportingBudgetApr, q.branchID)}</TableCell>
                                                    <TableCell>{InputWrapper('reportingBudgetMay', q.reportingBudgetMay, q.branchID)}</TableCell>
                                                    <TableCell>{InputWrapper('reportingBudgetJun', q.reportingBudgetJun, q.branchID)}</TableCell>
                                                    <TableCell>{InputWrapper('reportingBudgetJul', q.reportingBudgetJul, q.branchID)}</TableCell>
                                                    <TableCell>{InputWrapper('reportingBudgetAug', q.reportingBudgetAug, q.branchID)}</TableCell>
                                                    <TableCell>{InputWrapper('reportingBudgetSep', q.reportingBudgetSep, q.branchID)}</TableCell>
                                                    <TableCell>{InputWrapper('reportingBudgetOct', q.reportingBudgetOct, q.branchID)}</TableCell>
                                                    <TableCell>{InputWrapper('reportingBudgetNov', q.reportingBudgetNov, q.branchID)}</TableCell>
                                                    <TableCell>{InputWrapper('reportingBudgetDec', q.reportingBudgetDec, q.branchID)}</TableCell>
                                                    <TableCell align="right">{formatters.ToFixedSeparator(q.totals) || 0}</TableCell>
                                                </TableRow>
                                            );
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ReportingBudgetTable;
