import React, { useEffect, useState, useContext } from 'react';
import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton } from '../../../Core/FormInput/index';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import AddWorkflowModal from './AddWorkflow';
import { getWorkflows, getWorkflowsByProcessID } from '../../../Core/Service/workflow-service';
import AppContext from '../../../App/AppContext';
import DeleteWorkflow from './DeleteWorkflow';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Link } from 'react-router-dom';
import BuildIcon from '@material-ui/icons/Build';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import DataGridComp from '../../../Core/Grid';

const WorkflowList = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,
        totalRec: 0,
        workflowId: null,
        showDel: false,
        WorkflowDetails: []
    });

    const { showToast } = useContext(AppContext);

    const { process } = props;

    useEffect(async () => {
        pullWorkflowAndUpdateState();
    }, []);

    const handleUpdate = (params) => {
        const workflow_id = params.value.row.id;
        setState((st) => {
            const nst = {
                ...st,
                show: true,
                workflowId: workflow_id,
                WorkflowDetails: params.value.row
            };
            return nst;
        });
    };
    const handleDeleteWorkflow = (params) => {
        const workflow_id = params.value.row.id;
        setState((st) => {
            const nst = {
                ...st,
                showDel: true,
                workflowId: workflow_id
            };
            return nst;
        });
    };
    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Workflow" aria-label="add">
                    <IconButton size="small" onClick={() => handleUpdate(params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Workflow Steps" aria-label="add">
                    <Link to={`/steps/${params.value.row.id}`}>
                        <IconButton size="small">
                            <BuildIcon color="secondary" fontSize="small" />
                        </IconButton>
                    </Link>
                </Tooltip>
                <Tooltip title="Go To Return Reason" aria-label="add">
                    <Link to={`/returnreason/${params.value.row.id}`}>
                        <IconButton size="small">
                            <AssignmentReturnIcon color="secondary" fontSize="small" />
                        </IconButton>
                    </Link>
                </Tooltip>
                <Tooltip title="Delete Workflow" aria-label="add">
                    <IconButton size="small" onClick={() => handleDeleteWorkflow(params)}>
                        <DeleteOutlineIcon className="SetButtonColor" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const getClass = (params) => {
        if (params.row.isDeleted) {
            return 'Deleted-record';
        }
    };
    const column_new = [
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 140,
            renderCell: (params) => <Arrow value={params} />
        },
        {
            field: 'id',
            headerName: 'ID',
            flex: 2,
            sortable: false,
            hide: true
        },
        { field: 'workflowName', headerName: 'Name', width: 250 },
        {
            field: 'nextWorkflowId',
            headerName: 'Next Workflow',
            flex: 2,
            valueGetter: (params) =>
                state.rows
                    .filter((m) => params.value == m.id)
                    .map((res) => {
                        return res.workflowName;
                    })
        },
        {
            field: 'orderIndex',
            headerName: 'Index',
            flex: 1
        },
        {
            field: 'isTechnicianWorkflow',
            headerName: 'Technician',
            valueGetter: (params) => (params.value ? 'Yes' : 'No'),
            flex: 1
        },
        {
            field: 'isEntryPoint',
            headerName: 'First Workflow',
            flex: 1,
            valueGetter: (params) => (params.value ? 'Yes' : 'No')
        },
        {
            field: 'isEndPoint',
            headerName: ' Last Workflow',
            flex: 1,
            valueGetter: (params) => (params.value ? 'Yes' : 'No')
        }
    ].map((c) => ({ ...c, cellClassName: getClass }));

    const pullWorkflowAndUpdateState = async () => {
        let result = await getWorkflowsByProcessID(process.processID, 'Y')
        if (result.success) {
            setState((st) => ({
                ...st,
                rows: result.data
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };

    const handleAdd = (props) => {
        setState((st) => {
            return {
                ...st,
                show: true,
                workflowId: null
            };
        });
    };
    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No workflow available</div>
            </GridOverlay>
        );
    }
    const closeUpdateUser = (refreshGrid) => {
        setState((st) => ({ ...st, show: false, showDel: false }));

        refreshGrid && pullWorkflowAndUpdateState();
    };
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={handleAdd} className="btnadd">
                    Add workflow
                </SecondaryButton>
            </div>
            {state.show ? <AddWorkflowModal workflowProcessID={process.processID} workflowId={state.workflowId} onFormSubmit={closeUpdateUser} WorkflowDetails={state.WorkflowDetails} /> : null}
            {state.showDel ? <DeleteWorkflow workflowId={state.workflowId} onFormSubmit={closeUpdateUser} /> : null}

            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={244} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};
export default WorkflowList;