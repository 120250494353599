import React, { useMemo } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel, Breadcrumbs } from '@material-ui/core';
import { TextBox } from './../../../../../Core/FormInput';
// import NestedGijgoGrid from './../../../../Core/Controls/GijgoGrid/nestedGijgoGrid';
import { Link } from 'react-router-dom';
// import BreadCrumbs from './../../../../Core/Controls/Breadcrumb';
import NestedGijgoGrid from './../../../../../Core/Controls/GijgoGrid/nestedGijgoGrid';
import BreadCrumbs from './../../../../../Core/Controls/Breadcrumb/index';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { useState } from 'react';
import MotHistoryScreen from '../../MotHistoryScreen';
import DialogComp from '../../../../../Core/Modal/dialogModal';
import ReactDOM from 'react-dom';
import { getReportsSalesDropdownsResolveData } from '../../../../../Core/Service/reportService';

var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let addCommas = (val) => {
    return val ? `£${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '0.00';
};

const baseColumns = [
    { title: 'Stock No', field: 'sn', width: 80, sortable: true },
    { title: 'Comm Number', field: 'cn', width: 90, sortable: true },
    { title: 'Reg Number', field: 'rn', width: 100, sortable: true },
    {
        title: 'Progress',
        field: 'pd',
        width: 80,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'progressCodes',
        dropDownValueField: 'id',
        dropDownLableField: 'name'
    },
    {
        title: 'Model',
        field: 'mc',
        width: 90,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'modelRecords',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'Stock Days',
        field: 'd',
        width: 90,
        sortable: true,

        cssClass: 'text-center'
    },
    {
        title: 'Type',
        field: 'mac',
        width: 70,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'modelAnalysisCodes',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'Branch',
        field: 'bn',
        width: 100,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'branchList',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'Customer',
        field: 'cust',
        width: 100,
        sortable: true
    },
    {
        title: 'Sold By',
        field: 'sp',
        width: 90,
        sortable: true
    },
    {
        title: 'Latest Profit',
        field: 'latestProfitValue',
        width: 100,
        sortable: true,
        align: 'right',
        renderer: function (value, record) {
            return addCommas(value);
        }
    },
    {
        title: 'Cost',
        field: 'cost',
        width: 70,
        sortable: true,
        renderer: function (value, record) {
            return addCommas(value);
        },
        align: 'right'
    },
    {
        title: 'Order Date',
        field: 'od',
        width: 100,
        sortable: true,

        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'Build Date',
        field: 'bd',
        width: 100,
        sortable: true,

        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'Cons Date',
        field: 'cd',
        width: 100,
        sortable: true,

        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'Est Date',
        field: 'estimDeliveryDate',
        width: 100,
        sortable: true,

        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    }
];

const detailTemplate = () => {
    return `<div style='width:100%'><table style='width:100%;'><tr style='background-color:#DCDCDC;' ><th style='font-weight:750; text-align:left;'>Vehicle Description</th ><th style='font-weight:750; text-align:left;'>Notes</th><th style='font-weight:750; text-align:left;'>Est Profit</th></tr>
        <tr><td>&nbsp;&nbsp;{vd}</td><td>&nbsp;&nbsp;&nbsp;&nbsp;{n}</td><td>&nbsp;&nbsp;&nbsp;&nbsp;£{estimatedProfit}</td></tr></div>`;
};

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'Sales Work In Progress', active: true }
];

const SalesWorkinProgress = (props) => {
    const [state, setState] = useState({ showMotHistory: false });

    const regNumberRendrer = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                onClick={() => setState((st) => ({ ...st, showMotHistory: true, regNo: record?.rn, FranchiseCode: record?.f }))}
                style={{ cursor: 'pointer' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let regNumberCol = tempCols.find((element) => element.field === 'rn');
        if (regNumberCol) {
            regNumberCol.renderer = regNumberRendrer({});
        }

        return tempCols;
    }, []);

    const baseUrl = `Sales/NewVehicleOrdersWIP`;
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <Grid container>
                <Grid container>
                    <NestedGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        displayPagination={true}
                        detailTemplate={detailTemplate}
                        downloadName={'Sales Work In Progress'}
                        dropDownListProvider={getReportsSalesDropdownsResolveData}
                    />
                </Grid>
            </Grid>
            {state.showMotHistory && (
                <DialogComp title="MOT History" onClose={() => setState((st) => ({ ...st, showMotHistory: false }))} maxWidth="md" fullWidth overflow={'auto'}>
                    <MotHistoryScreen regNo={state.regNo} FranchiseCode={state.FranchiseCode} hideSeachText={true} />
                </DialogComp>
            )}
        </div>
    );
};

export default SalesWorkinProgress;
